.resize-panel {
  &-container {
    flex: 1;
    display: flex;
    align-items: stretch;
    &-horizontal {
      flex-flow: row nowrap;
    }

    &-vertical {
      flex-flow: column nowrap;
    }
  }

  &-content {
    flex-grow: 1;
    align-self: stretch;
    display: flex;

    &-vertical {
      flex-flow: column;
      opacity: 1;
      transition: 0.5s ease;
      &-hide {
        height: 0!important;
        opacity: 0;
        transition: 0.5s ease;
      }
    }

    &-horizontal {
      flex-flow: row;
      opacity: 1;
      transition: 0.5s ease;
      &-hide {
        width: 0!important;
        opacity: 0;
        transition: 0.5s ease;
      }
    }
  }
}
