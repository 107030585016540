.entities-table{
  flex: 1;
  display: flex;
  min-width: 1rem;
  &-wrapper {
    align-self:stretch;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: 0.5s ease-out;
    max-height: 100%;
    position: relative;
  }

  &-preloader {
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    top:0;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .1);
  }

  .rt-expander{
    &:after{
      border-top-color: $gray4!important;
      border-color: $gray4!important;
    }
  }

  &-content {
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    overflow: auto;

    table {
      // flex: 1;
      width: auto;
      min-width: 100%;

      border-spacing: 0;
      border-collapse: separate;
      thead {
        position: sticky;
        top: 0;
        z-index: 5;
        background: $gray2;
        height: 2.5rem;
      }

      tr {
        &:nth-child(even) {background: $grayblue1;}
        th {
          white-space: nowrap;
          text-align:center;
          font-size: .875rem;
          font-weight: 400;
          color: $dgray3;
          padding: 0 .5rem;
          border-top: 0.0625rem solid $gray6;
          border-bottom: 0.0625rem solid $gray6;
          border-right: 0.0625rem solid $gray6;
          &:last-child{
            border-right-width: 0;
          }


          &.num-header{
            width: 2rem;
            max-width: 4rem;
            min-width: 2rem;
          }
          &.title-header{
            width: 100%;
            min-width: 4.5rem;
            max-width: 8rem;
          }
          &.version-header{
            width: 4rem;
            min-width: 3rem;
            max-width: 6rem;
          }
        }

        td {
          align-items: flex-start;
          justify-content: flex-start;
          padding:0;
          margin: 0;
          overflow: hidden;
          text-align: left;
          vertical-align: top;
          border-bottom: 0.0625rem solid $gray6;
          border-right: 0.0625rem solid $gray6;
          &:last-child{
            border-right-width: 0;
          }
        }
      }
    }
  }

  // &-column-header{
  //   margin: .5rem;
  //   text-align:center;
  //   font-size: .875rem;
  //   font-weight: 400;
  //   color: $dgray3;
  //   // font-style: italic;
  //   border-color: $gray6;
  //   line-height: 1.25rem;
  // }

  &-cell {
    flex: 1;
    height: 100%;
    padding: .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    font-size: .875rem;

    // justify-content: center;
    // align-items: flex-start;

    &-text{
      white-space: pre-wrap;
      text-align: left;
    }

    &-preloader{
      position: absolute;
      margin: auto;
      display: block;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      svg {
        margin: 0.25rem;
        margin-left: .125rem;
      }
    }

    &.id-cell {
      width: 100%;
      position: relative;
    }
  }
}