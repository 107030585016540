.issue-attachments {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &-body {
    flex:1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-title {
    color: $gray8;
    font-size: .875rem;
  }

  &-list {
    flex: 1;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-bottombar {
    margin-top: 1rem;
    padding-top: .5rem;
    border-top: .0625rem solid $gray4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &-attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &-title{
      flex: 1;
      font-size: .875rem;
      text-align: left;
      color: $dgray5;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
      .size {
        color: $gray7;
        padding-left: .25rem;
      }
    }

    &-buttons{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      .ais-button {
        margin: 0;
      }
    }
  }
}
