.schema-trace-list {
  margin-top: .25rem;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  &-empty{
    color: $gray7;
    font-size: 1rem;
    padding: 0 0.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-items {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    padding: .5rem;

    border: 0.0625rem solid $gray4;
    border-radius: .25rem;
    
    @import "./trace-item.scss";
    @import "./trace-link.scss";
  }
}