.user-avatar{
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &-preloader {
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.1);
    z-index: 10;
  }

  &-image{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
