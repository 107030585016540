.library {
  .plus-container {
    .plus-list {
      display: flex;
      flex-shrink: 0;
      position: absolute;
      padding: .3rem;
      max-width: 40rem;
      flex-wrap: wrap;
      top: 2.5rem;
      z-index: 4;
      background: $dgray4;
      overflow: auto;
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }
}
.library-search{
  &-text{
    >.string{
      margin-top: 0;  
    }
    margin: .25rem;
  }
  
  &-results{
    position: relative;
    flex: 1;
    &-text{
      position: absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%, -50%);
      font-size: 1.25rem;
      color: $gray7;
    }
    &-item{
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      cursor: pointer;
      &>div:last-child{
        flex: 1;
      }
      margin: .125rem .5rem;
      padding: .5rem 0;
      border-bottom: 0.0625rem solid $gray7;
      &:hover{
        background-color: $gray3;
      }
    }

    &-num{
      width: 2rem;
      margin: auto;
    }
  }
}
.import-form-modal {
  min-width: 30rem;
}
.node-name:focus {
  outline: transparent;
  background-color: $blue3;
  padding: 0.15rem;
  transition: all 0.3s ease;
}