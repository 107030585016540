.issue-journal {
  // align-items: stretch!important;
  flex-grow: 1;
  &-wrapper {
    align-self:stretch;
    flex: 1;
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
  }

  .no-content, .no-records {
    margin: 1rem;
    color: $dorange1;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;
  }

  &-content {
    flex: 1;
    display: flex;
    overflow: auto;
  }

  &-header{
    background-color: $orange2;
    color: $dorange3;
    svg {
      background-color: $orange;
      color: $white;
    }
  }

  &-shown svg{
    color:$orange!important;
  }

  &-list {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    background: $orange1;
    &::-webkit-scrollbar {
      width: .5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $orange1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $halforange;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background:  $mainorange;
    }

    &-pagination{
      background: $orange2;
      border-color: $orange3;
      color: $dorange1;
      input {
        border-color: $dorange1;
      }

      .pagination-page-size {
        color: $dorange1;
        .active {
          color: $dorange2;
        }
      }

      .pagination-button {
        color: $dorange1;
        &.disabled {
          color: $halforange;
        }
      }
    }

    &-items {
      flex: 1;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      overflow: auto;
      @import "./journal-item";
    }
  }
}
