.search-entites-filters{
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .ais-field-container{
    flex: 1;
    input{
      width: 100%;
    }
  }

  .filters {
    &-header{
      border-bottom: 0.0625rem solid $gray6;
      font-weight: 100;
      font-size: 1.25rem;
      line-height: 2.35rem;
      text-align: left;
      background-color: $gray3;
      color: $dgray3;
      span {
        padding: 0.25rem;
      }
      svg {
        width: 1.75rem;
        height: 1.75rem;
        margin-left: 0.25rem;
        flex-shrink: 0;
      }
    }
    &-body{
      flex: 1;
      overflow: auto;
      background-color: $gray2;
      display: flex;
      flex-direction: column;

      .filter-kind, .filter-version {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 0 1rem;
        text-align: left;
        color: $dgray1;
        
        &>svg {
          font-size: 1.5rem;
          padding-bottom: .5rem;
          padding-left: .5rem;
          // width: 1.5rem;
          // height: 1.5rem;
          cursor: pointer;
        }
      }

      .filter-version {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }
    &-footer {
      border-top: 0.0625rem solid $gray6;
      background-color: $gray3;
      color: $dgray3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: .415rem 0;
    }
  }
}