@import "variables.scss";
.wizard-tool-holder {
  flex-direction: column;
  width: 100%;
}
.wizard-preloader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wizard-tool-content2 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  color: $white;
  min-width: 20rem;
  margin: -1rem;

  .control-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // background-color: $dgray2;
    border-top: 0.0625rem solid $dgray2;
    padding: .5rem;

    .uif-components-button{
      height: 2rem;
    }

    .revert-button{
      .uif-components-button{
        flex-direction: row-reverse;
        padding: 0 0.25rem 0 0.5rem;
      }   
    }

    &-left, &-right{
      flex: 1;
      display: flex;
      flex-direction: row;
    }
    &-left{
      justify-content: flex-start;
    }
    &-right{
      margin-left: .5rem;
      justify-content: flex-end;
    }
  }

  
  .ais-field-label {
    color: $white;
  }

  .divider {
    height: 0.0625rem;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0.25rem 0;
  }

  .ais-input-holder .label {
    background: transparent;
    padding: 0.2rem 0;
    color: #000;
    font-size: 0.875rem;
    padding-bottom: 0.125rem;
  }

  .buttons-holder {
    flex-direction: row;
    display: flex;
  }
}