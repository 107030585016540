@import "variables.scss";

.search-entities-tool{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    background: $white;
    overflow: hidden;
    position: relative;
    width: 100%;
    @import "../../components/index.scss";
  }
  &-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .Pane1{
    overflow: auto;
  }
  .Pane2{
    display: flex;
    flex: auto;
    overflow: hidden;
    &.hidden {
      display: none;
    }
  }
}
