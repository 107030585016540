.dropdown-menu{
  &-button{
    position: relative;
  }

  &-list{
    position: absolute;
    top: 2.125rem;
    display: none;
    &.show{
      display: flex;
      flex-direction: column;
      z-index: 10;
    }

    button{
      // margin: .25rem 0;
      margin-bottom: .125rem!important;
    }
  }

}