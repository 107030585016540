.trace-analyzer-tool {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  
  .trace-preloader{
    z-index: 5;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,0.1);
  }

  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: $white;
    overflow: hidden;
    position: relative;

    .empty-list,
    .no-content,
    .no-records {
      margin: 1rem;
      color: $gray8;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 300;
      justify-self: center;
    }
  }
}
