.issue-related-issues-menu {
  position: absolute;
  z-index: 5;
  top: 3.125rem;
  right: 0;
  background-color: $gray2;
  border: .0625rem solid $gray6; 
  border-radius: .25rem;
  min-width: 20rem;
  // padding: .5rem;

  box-shadow: 0 .875rem 4rem rgba(0,0,0,0.25), 0 4rem 4rem rgba(0,0,0,0.22);

  
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    right: .5rem;
    top: -.5rem;
  }

  &:before {
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid $gray6;
      border-left: .5rem solid transparent;
  }

  &:after {
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid $gray2;
      border-left: .5rem solid transparent;
      top: -.4375rem;
  }

  &.disabled {
    display: none;
  }

  &.show {
    display: block;
  }

  &-item{
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: .0625rem solid $gray6; 
    padding: .5rem;
    color: $dgray3;
    cursor: pointer;
    &:last-child{
      border-bottom-width: 0;
    }

    &:hover {
      background-color: $blue1;
    }
    .icon{
      font-size: 1.5rem;
    }
    span{
      flex: 1;
      padding-left: .5rem;
    }
  }
}