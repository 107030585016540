@import "variables.scss";

.issues-tool{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    background: $white;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  @import "../../components/index.scss";
}

// тк wizard отображается в модальном окне, нужно выести на пределы интструмента
@import "../../components/coordLetter/css";
