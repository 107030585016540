@import "colors";

html,
body {
  font-size: 16px !important;
}

.uif-modal-window {
  .ais-field-container {
    .ais-field-label {
      color: #fff;
    }
  }
}
.library {
  background: #fff;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;

  .preload-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.1);
  }

  &-tool {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &-item {
      flex: 1;
      overflow: hidden;
      display: flex;
      &.disabled {
        display: none;
      }
    }
  }

  .node-content {
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    font-weight: 100;
    .expander.big {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.selected {
      background-color: $blue4;
    }
  }
  .drop-target-layer {
    flex: 1;

    .react-contextmenu-wrapper {
      flex: 1;
    }
  }

  .no-representation-wrapper {
    color: $orange;
    flex: 1;
    display: flex;
    font-size: 2rem;
    font-weight: 100;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .no-representation-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .editor .editor-content {
    background: transparent;

    .editor-item {
      border: none;
    }
  }

  > .preloader {
    margin: auto;
  }
}

.tree-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .node {
    color: #000;
    font-size: 0.875rem;

    &.even {
      background-color: #f4f4f4;

      td {
        .merge-icons {
          .lock-icon {
            .lock {
              stroke: #f8f8f8;
            }
          }
        }
      }
    }

    &.cursor {
      td {
        .merge-icons {
          .lock-icon {
            .lock {
              stroke: #cdefff;
            }
          }
        }
      }
    }
  }

  td {
    position: relative;
    width: 0.01rem;
  }

  tr:not(.spacer) td:not(:last-child):after {
    display: block;
    position: absolute;
    height: 90%;
    width: 0.0625rem;
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.04);
    top: 5%;
    right: 0;
  }

  // td:empty {
  //   display: none;
  // }
}
.ais-wm-version-forms {
  display: flex;
  margin-left: 0.5rem;
  padding-left: 0.25rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 0.125rem;
    height: 100%;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.1);
    border-radius: 0.25rem;
  }
}
.node {
  font-weight: 100;
  color: #000 !important;
  font-size: 0.875rem;

  /*margin-top: 0.25em;*/
  div.version-user {
    display: flex;
    height: 1.5rem;
    line-height: 1.5rem !important;
    vertical-align: middle;
    align-items: center;
    .user-avatar {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      margin: 0.0625rem;
      margin-right: 0.5rem;
    }

    .user-name {
      margin: 0;
      color: $gray50;
    }
  }

  .wf-user {
    .user-wrapper {
      flex-direction: row;
      display: flex;
      white-space: nowrap;
      margin: 0 0.25rem;

      .user-avatar {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0;
      }

      .user-name {
        margin: 0;
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0.5rem;
      }
    }
  }

  div.wf-status {
    display: flex;
    margin: 0 0.25rem;

    .status-icon {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      color: #fff;
      margin: 0.125rem;

      .expander.big {
        font-size: 1.25rem !important;
        vertical-align: top !important;
        margin: 0 0.025rem;
      }
    }

    &.ready,
    &.examined,
    &.verified,
    &.approved,
    &.use,
    &.accepted,
    &.done,
    &.positive {
      color: $darkgreen40;

      .status-icon {
        background: $green;
      }
    }

    &.approved,
    &.created,
    &.agreed,
    &.suspended,
    &.assigned,
    &.initial {
      color: $darkorange40;

      .status-icon {
        background: $orange;
      }
    }

    &.rejected,
    &.canceled,
    &.closed,
    &.negative {
      color: $red;

      .status-icon {
        background: $red;
      }
    }

    &.progress,
    &.rework,
    &.base,
    &.neutral {
      color: $mainblue;

      .status-icon {
        background: $mainblue;
      }
    }

    .status-text {
      margin-left: 0.5rem;
      line-height: 1.5rem;
      font-size: 0.75rem;
      text-align: left;
      flex-shrink: 0;
    }
  }
}

.icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0em 0em 0;
  color: gray;
  user-select: none;
}

.icon:hover {
  cursor: pointer;
}

.collapsed {
  transform: rotate(0deg);
}

.label {
  display: inline-block;
  vertical-align: middle;
  padding: 0.2em;
}

td.vf {
  margin: 0;
  padding: 0;
}

.ais-wm-version-forms {
  display: flex;
}

.ais-wm-version-forms span:hover {
  color: #0e89c2;
  cursor: pointer;
}

.node-renderer {
  display: flex;
  flex: 1;
}

.node-inner-content {
  display: flex;
  flex: 1;
  justify-content: spase-between;
  font-family: Helvetica, arial, sans-serif;

  &.disabled {
    opacity: 0.5;
  }

  .editor {
    .editor-content {
      font-family: Helvetica, arial, sans-serif;
    }
  }
}

.node-inner {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;

  .node-name {
    text-align: left;
  }
}

.node-content {
  flex: 1;

  div {
    line-height: initial;
    font-family: Helvetica, Roboto, Arial, sans-serif !important;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    flex-direction: row;

    .editor-content {
      .editor-item-content {
        min-height: auto;
      }
    }
  }
}

.ais-wm-version-number {
  display: flex;
  width: auto;
  padding: 0;
  min-width: 6rem;
}

.node .icon-holder {
  margin-right: 0.25rem;
}

.node .expander.big {
  &.disabled {
    opacity: 0.5;
  }

  padding: 0;
  font-size: 1.5rem !important;
  margin: 0 !important;
}

@import "../../components/css";
