.ais-object {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem;
  border: 0.0625rem solid $gray6;
  border-radius: 0.25rem;
  // @ai: без "min-width: 0" не будет работать ellipsis
  // см. https://stackoverflow.com/q/67682635
  min-width: 0;

  &:hover {
    cursor: pointer;
    background-color: $gray2;
  }

  .object {
    display: flex;
    min-width: 0;
    max-width: 30rem;
    align-items: center;
    text-align: left;
    flex: 1;

    .icon {
      flex-shrink: 0;
      width: 1.25rem !important;
      height: 1.25rem !important;
      color: $black;
      margin: 0;
    }

    .label {
      max-height: 1.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      user-select: none;
      flex: 1;
    }

    .version {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.1);
      padding: 0rem 0.25rem 0rem 0;
      border-radius: 0.25rem;
      font-size: 0.85rem;
    }
  }

  &-tooltip{
    max-width: 22rem;
    overflow: hidden;
    svg {
      font-size: 1.5rem;
    }

    label{
      font-weight: 600;
    }
    &-empty{

    }

    &-parent{
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    &-path{
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;      
      &-item{
        display: flex;
        align-items: center;
        flex-direction: row;
        line-height: 1.875rem;
        &:not(:last-child):after{
          padding-left: .25rem;
          content: "/";
          color: $gray8;
          font-weight: 400;
          font-size: 1.75rem;
        }
      }
    }

    &-go{
      // border-top: 0.0625rem solid $gray2;
      font-style: italic;
      text-align: right;
      white-space: nowrap;
    }
  }
}
