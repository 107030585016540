@import "variables.scss";

.ws-tool{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  @import "../../components/index.scss";
}
