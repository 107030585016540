.filters-info {
  padding: .5rem 0;
  background-color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &-empty{
    color: $dgray1;
    font-size: .875rem;
    margin: .175rem .5rem;
  }
  &-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    font-size: .875rem;
    margin-left: .5rem;
    .label{
      color: $dgray3;
    }

    .value{
      color: $dgrayblue1;
      // text-transform: lowercase;
    }

    &:after {
      content: "";
      border-right: .0625rem solid $dgray3;
      height: .875rem;
      margin-left: .5rem;
    }
    &:last-child:after{
      border-right:0;
    }
  }


}
