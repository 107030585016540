@import "variables.scss";

.journal-sidepanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  .target-object {
    background: $orange2;
    border-bottom-color: $orange3;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: $orange1;
    overflow: hidden;

    .no-content,
    .no-records {
      margin: 1rem;
      color: $dorange1;
      text-align: center;
      font-size: 1.1rem;
      font-weight: 300;
    }

    @import "../../components";
  }

  .target-info {
    &:hover {
      color: $dorange1;
    }
  }

}