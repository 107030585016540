.trace-analizer-parameters{
  border-bottom: 0.0625rem solid $gray4;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  .ais-field-container{ flex: 1}

  &.full-page {
    flex: 1;  
  }

  &.no-schema{
    border-bottom-width: 0;
  }

  @import "./info";
  @import "./schemas";
  @import "./schema-parameters";
}
