.indicator-container {
  display: flex;
  width: 0.25rem;
  
  .indicator {
    display: none;
    width: 0.25rem;
    height: 100%;
    margin: 0 0.0625rem 0 0;
    border-radius: 0.125rem;

    &.kinds,
    &.relations {
      display: block;
    }

    &.kinds{
      background: $mainviolet;
    }

    &.relations{
      background: $maingreen;

      &.kinds{
        background: linear-gradient(to bottom, transparent, transparent 4%, $mainviolet 4%, $mainviolet 48%, transparent 48%, transparent 52%, $maingreen 52%, $maingreen 100%);
        background-repeat: repeat;
        background-size: 1rem 1.85rem;
      }  
    }

  }
}
