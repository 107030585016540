.schema-trace-item {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  color: $dgray3;
  position: relative;

  border: 0.0625rem solid $gray6;
  border-radius: .25rem;

  &-title {
    // background-color: $lightblue;
    background-color: $grayblue3;
    color: $dgrayblue2;
    border-bottom: 0.0625rem solid $blue1;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    padding: .5rem;

    color: $dgrayblue2;
    label{
      font-weight: 600;
    }
  }

  &-target{
    padding: .25rem .5rem;
    background-color: $lightblue; 
    border-bottom: 0.0625rem solid $blue1;
    display: flex;
    flex-direction: row;
    .target-item{
      font-size: .875rem;
      margin-right: .5rem;
      color: $dgray1;
      label{
        margin-right: .25rem;
        font-weight: 600;
      }
    }
  }

  &-boundaries {
    margin: .5rem;
    &-empty {
     font-size: .875rem;
     color: $gray8;
    }
    @import "./boundary.scss";
  }
}
