@import "variables";

$fontcolor: #3bc0a0;
$darkfont: #529384;
$backcolor: white;
$deepbackcolor: #ccf4eb;
$bordercolor: #c4f2e7;
$cursorbackground: #def9f2;

.validation-tracker {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.validation-tool-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $backcolor;

  .uif-components-button {
    height: 2rem;
    min-width: 2rem;
    padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  }

  .comment-holder {
    padding: 1rem 0.5rem 0;
    text-align: left;
  }
  .buttons-holder {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    border-bottom: 0.0625rem solid #f0f0f0;
    padding: 0.375rem;
  }

  .preload-wrapper {
    // margin: 2rem auto;
    position: absolute;
    left:0;
    top:0;
    right: 0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.1);
  }

  .validate{
    width: 2rem;
    height: 2rem;
    stroke: none;
    &.rejected {
      fill: #b4000e;
    }

    &.accepted {
      fill: #00b429;
    }
  }

  .user-holder {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 100;
    line-height: 2rem;
    padding: 0.25rem 0.5rem;
    border-bottom: 0.0625rem solid #f0f0f0;

    .ais-button {
      margin: 0;
    }
    &.accepted {
      background: #ccf4eb;
    }

    &.rejected {
      background: #f4cccf;
    }

    .user-name {
      flex: 1;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .user-avatar {
      flex: none;
      margin-right: 0.5rem;
    }
    .user-avatar-image {
      width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      overflow: hidden;
      flex-shrink: 0;
    }
  }
}
