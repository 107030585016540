.repo-tree {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .tree-container{
    overflow: visible;
  }
  .dnd-source, .dnd-target {
    flex: 1;
  }

  
}