.issue-custom-field{
  &-attachment{
    flex: 1;
    display: flex;
    flex-direction: row;
    &-buttons{
      display: flex;
      padding-top: .85rem;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      .ais-button {
        margin: 0;
      }
    }
  }
}