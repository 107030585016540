// colors
@import "~@ais3p/ui-framework/colors.scss";
@import "variables";

.App {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #222;
  overflow: hidden;

  .ais-button {
    .ais-button-text {
      font-weight: 100;
    }
  }
  .list-holder {
    flex: 1;
    background: #fff;
    overflow: auto;
  }
  .ReactTable {
    border: none;

    .rt-thead {
      background-color: $gray3;
      box-shadow: none;
      border: none;
      border-bottom: 0.0625rem solid $gray6;

      .rt-th {
        padding: 0;
        border: none;
        align-items: flex-start;
        text-align: justify;
        border-left: 0.0625rem solid $gray6;
        box-shadow: none !important;

        .sort {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          justify-content: center;
          align-items: center;
          width: 1.5rem;
          padding: 0 0.25rem;

          background: linear-gradient(to right, transparent, #f3f3f3 25%);

          &.none {
            display: flex;
          }
        }

        &.-sort-asc {
          .sort {
            display: none;

            &.asc {
              display: flex;
            }
          }
        }

        &.-sort-desc {
          .sort {
            display: none;

            &.desc {
              display: flex;
            }
          }
        }

        .filter-icon {
          cursor: pointer;
          color: $mainblue;

          &:hover {
            color: $mainred;
          }
        }

        .rt-resizable-header-content {
          height: 100%;
        }
        .column-header {
          padding: 0.5rem;
          margin: 0;
          .column-header-text {
            font-weight: 100;
            font-size: 0.75rem;
            line-height: 1rem;
          }

          .object-icon {
            font-size: 1rem;
            height: 1.5rem;
            width: 1.5rem;
            margin: -0.25rem 0.5rem -0.25rem 0;
          }

          &.delete {
            margin: 0;
            width: 2rem;
            height: 2rem;
            font-size: 1.5rem;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.direction {
            width: 3rem;
            height: 2rem;
            font-size: 1.5rem;
            padding: 0;
            display: flex;
            align-items: center;
            padding-left: 0.25rem;
            justify-content: flex-start;
          }
        }
      }
    }
    .rt-tbody {
      .rt-tr-group {
        border: none;
      }
      .rt-tr {
        border: none;
        border-bottom: 0.0625rem solid $gray4;

        &.selected {
          background: $mainblue;
        }

        .rt-td {
          border: none;
          padding: 0;
          align-items: flex-start;
          text-align: justify;
          border-left: 0.0625rem solid $gray4;
          font-weight: 100;
          font-size: 1rem;

          .column-cell {
            padding: 0.5rem;
            line-height: 1rem;
            position: relative;

            .cell-ending {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              cursor: pointer;
              right: 0;
              top: 0;
              height: 100%;
              width: 2.5rem;
              background: linear-gradient(to right, transparent, #ffff 25%);

              .link-icon {
                font-size: 1rem;
                height: 1.5rem;
                width: 1.5rem;
                margin: -0.25rem 0;
              }

              &:hover {
                color: $mainblue;
              }
            }

            .object {
              position: relative;
              cursor: pointer;
              &:hover {
                color: $mainblue;
              }

              &.selected {
                color: $mainblue;
              }
              .object-icon {
                font-size: 1rem;
                height: 1.5rem;
                width: 1.5rem;
                margin: -0.25rem 0;
              }
              .object-name {
                line-height: 1rem;
                vertical-align: middle;
                display: inline-block;
                margin-left: 0.5rem;
              }
            }

            &.delete {
              width: 2rem;
              height: 2rem;
              font-size: 1.5rem;
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              .ais-button {
                align-items: center;
                justify-content: center;
                margin: 0.25rem;
                width: 1.5rem;
                height: 1.5rem;
                color: #fff !important;
                .ais-button-icon {
                  font-size: 1.5rem !important;
                }
              }
            }
            &.direction {
              width: 3rem;
              height: 2rem;
              font-size: 1.5rem;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .table-editor {
    .table-editor-body {
      .merge-icons {
        display: inline-block;
        .lock-icon {
          .lock {
            stroke: $dgray4;
          }
        }
      }
    }
  }
  .validate {
    stroke: #fff;
    fill: $green;
    paint-order: stroke;
    stroke-width: 1rem;

    &.in-progress {
      fill: $dorange1;
    }
    &.black {
      fill: $dgray4;
    }
  }
  .overlay-blur {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    backdrop-filter: blur(0px);
    transition: backdrop-filter linear 0.3s;

    &.visible {
      display: block;
      backdrop-filter: blur(5px);
    }
  }

  span {
    text-shadow: none !important;
  }

  iframe.test-viewer-pdf {
    flex: 1;
    display: flex;
    width: 100%;
    // height: 100%;
  }
  .app-error,
  .app-confirm {
    position: absolute;
    width: 30rem;
    background: #404040;
    left: 50%;
    color: #fff;
    transition: all 0.5s ease 0s;
    border-radius: 0.25rem 0.25rem;
    overflow: hidden;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.7);

    .error-header {
      font-size: 1.5rem;
      font-weight: 100;
      background: #d9534f;
      padding: 0.5rem;
    }

    .confirm-header {
      font-size: 1.5rem;
      font-weight: 100;
      background: $mainblue;
      padding: 0.5rem;
    }

    .ais-buttons-holder {
      display: flex;
      flex-direction: row;

      .ais-button {
        flex: 1;
      }
    }
    .ais-button {
      margin: 0;
      padding: 0.5rem;
      height: 3rem !important;
      border-radius: 0;
    }

    .error-text,
    .confirm-text {
      font-size: 1.2rem;
      font-weight: 100;
      padding: 2rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .app-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    transition: all 0.3s ease 0s;
    transform-origin: bottom;
    transform: rotateX(0deg);
    background-position: center;
    background-size: cover;
    overflow: hidden;

    &.error,
    &.confirm {
      // transform: rotateX(15deg);

      .error-overlay {
        display: block;
        opacity: 0.5;
      }
    }

    .layout-panel {
      &.fixed {
        min-width: 20rem;
        min-height: 10rem;
        border-radius: 0.5rem;
        max-height: 80vh;
        flex: none;

        .dnd-target {
          &.layout-panel-tabs-holder {
            align-items: stretch;
            flex-direction: column;
          }
        }
        .layout-panel-header {
          height: 3rem;
          .dnd-source {
            &.tab-dnd-holder {
              flex: 1;
              p{
                max-width: none !important;
              }
              svg{
                width: 2rem;
                height: 2rem;
                margin-right: 0.25rem;
              }
            }
          }

          .layout-tab.elipsis.current {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #272727;
            color: #e6e6e6;
            font-size: 1.5rem;
            height: 3rem;
            padding: 0 1rem;
            text-overflow: ellipsis;
            flex: 1;
          }
          > .ais-button {
            display: none;
          }
        }
      }
      .ais-input-holder {
        .label {
          background: transparent;
          padding: 0.2rem 0;
          font-size: 0.875rem;
          padding-bottom: 0.125rem;
        }

        .ais-input-row {
          .ais-input-div {
            input.ais-input {
              box-sizing: border-box;
              // padding: 0.485rem !important; // nego: зачем было выставлено .485rem? С этим плывет бордюр Field.String
            }
          }
        }
      }
    }

    &.login {
      background-image: url(/fws.gif);

      .ais-overlay .ais-modal {
        background: #404040;
        border: none;

        .login-modal-content {
          font-weight: 100;
          margin-top: 1.5rem;
        }
        .ais-buttons-holder {
          margin: 0;

          .ais-button {
            border-radius: 0;
            flex: 1;
            margin: 0;
            height: 3rem !important;
            padding: 0.5rem;
          }
        }
        .ais-modal-divider {
          display: none;
        }
        .ais-header {
          color: #ffffff;
          padding: 0;
          background: #222;
          height: 3rem;
          line-height: 3rem;
          margin: 0;
          padding: 1rem;
          font-size: 1.5rem;
          min-height: 2rem;
          height: 2rem;
          line-height: 2rem;
          border: none;
        }
        .ais-close-modal {
          display: none;
        }
      }
      .ais-modal-content {
        padding: 0 2rem 1.5rem;
        color: #fff;
      }
      .ais-input-holder .ais-input-div input {
        font-size: 1.33333rem;
        line-height: 3rem;
        border: none;
        padding: 0 1.5rem !important;
        background: #333;
        color: #fff;
      }
    }

    .error-overlay {
      z-index: 4000;
      background: #000;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      opacity: 0;
      display: none;
      transition: opacity 0.5s ease 0s;
    }
  }
  .ais-overlay {
    position: absolute;
  }
  .ais-overlay .ais-modal.dark {
    background: #404040;
    border: none;

    .ais-buttons-holder {
      margin: 0;
      min-height: 2.5rem;

      .ais-button {
        border-radius: 0;
        flex: 1;
        margin: 0;
        height: 2.5rem !important;
        padding: 0.25rem 0.5rem;
      }
    }
    .ais-modal-divider {
      display: none;
    }
    .ais-header {
      color: #ffffff;
      background: #222;
      margin: 0;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      min-height: 2rem;
      height: 2rem;
      line-height: 2rem;
      border: none;
    }
    .ais-close-modal {
      display: none;
    }
    .ais-modal-content > span {
      border-bottom: 0.0625rem solid #353535;
      padding-bottom: 0.5rem;
    }
    .ais-modal-content {
      padding: 0.5rem 1rem 1rem;
      color: #fff;

      .ais-input-holder .label {
        line-height: 0.7rem !important;
        font-size: 0.8rem;
      }
    }
    .ais-input-holder .ais-input-div input {
      font-size: 1rem !important;
      line-height: 2rem;
      border: none;
      padding: 0 0.5rem !important;
      background: #333;
      color: #fff;
    }
    .ais-input-holder .ais-input-div textarea {
      font-size: 1.33333rem;
      line-height: 1.5rem;
      border: none;
      padding: 0.5rem !important;
      background: #333;
      color: #fff;
    }
  }

  .ais-overlay .ais-modal {
    .ais-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 1rem;
      font-weight: 100;
      padding: 0 2rem;
      font-size: 2rem;
      font-size: 1rem;
      line-height: 3rem;
      text-align: center;
      margin: 0 1rem;
      min-height: 3rem;
      border-bottom: 0.0625rem solid #f3f3f3;
    }
  }
  .logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }

  .app-header {
    background-color: $dgray4;
    width: 3rem;
    padding: 0;
    flex-direction: column;
    flex-shrink: 0;
    display: flex;

    .button-wrapper {
      position: relative;
      display: flex;
      flex-shrink: 0;
      height: 3rem;
      padding: 0;
      /* flex: 1; */
      flex-direction: column;

      .ais-button-icon {
        min-width: auto !important;
      }

      .children {
        opacity: 0;
        position: absolute;
        left: 100%;
        width: auto;
        background: #333;
        flex: 1;
        display: flex;
        flex-direction: row;
        transition: all 0.5s linear;
        border-radius: 0 0.25rem 0.25rem 0;
        box-shadow: 0 0.125rem 0.25rem -0.15rem rgba(0, 0, 0, 0.5);

        .button-wrapper {
          padding: 0;
        }
      }

      &:hover {
        .children {
          opacity: 1;
          z-index: 5;
        }
      }

      &.connections {
        .ais-button {
          color: $pink !important;
        }
      }
      &.marker {
        .ais-button {
          color: $yellow !important;
        }
      }
    }

    .ais-button {
      margin: 0;
      padding: 0 0.25rem;
      height: 3rem;
      width: 3rem;
      border: none;
      color: $gray8 !important;

      &::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
      }
      &:hover {
        color: $gray8 !important;
      }
    }

    .spacer {
      height: 0.125rem;
      background: rgba(0, 0, 0, 0.2);
      height: width;
      padding: 0;
      margin: 0.25rem;
      border-radius: 0.25rem;
      display: flex;
    }

    .left-side {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: flex-start;

      .ais-logo-wrapper {
        position: relative;
        .ais-logo-icon {
          display: flex;
          width: 3rem;
          height: 3rem;
          color: $mainblue;
        }

        .ais-logo-ws-status,
        .ais-logo-request-pending {
          position: absolute;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
        }
        .ais-logo-ws-status {
          background-color: $mainred;
          &.connected {
            background-color: $maingreen;
          }

          &.left {
            top: 0.45rem;
            left: 0.25rem;
          }

          &.right {
            bottom: 0.25rem;
            right: 0.45rem;
          }
        }

        .ais-logo-request-pending {
          background-color: $white;
          left: 0.65rem;
          bottom: 0.65rem;
        }
      }
    }
    .right-side {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: flex-end;

      .app-user {
        width: 3rem;
        height: 3rem;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        &-avatar {
          // display: none;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .image {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.5rem;
            margin: 0.25rem;
            overflow: hidden;
          }
        }

        .logout {
          background: $dgray4;
          width: 100%;
          height: 100%;
          color: $white;
          opacity: 0;
          position: relative;

          transition: opacity 0.3s ease 0s;
          cursor: pointer;
          z-index: 4;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 2.5rem;
          }
        }

        &:hover {
          .logout {
            opacity: 1;
          }
        }

        &-preloader {
          z-index: 5;
          background: rgba(0, 0, 0, 0.7);
          color: $white;
          margin: 0.25rem;
          svg {
            position: absolute;
            bottom: 0.125rem;
            right: 0.125rem;
          }
        }

        // .photo {
        //   width: 2.5rem;
        //   height: 2.5rem;
        //   border-radius: 0.5rem;
        //   background-size: cover;
        //   background-position: center;
        //   margin: 0.25rem;
        //   overflow: hidden;
        //
        //
        // }
      }
    }
  }

  .title {
    font-size: 1.5em;
    // background: $white;
  }

  .intro {
    font-size: large;
    background: $black;
  }

  .tooltip {
    padding: 5px;
    position: absolute;
    z-index: 9999;
    cursor: default;
    animation: 0.5s fadeIn;
    &-content {
      padding: 10px;
      border-radius: 5px;
      background: $dgray4;
      color: $white;
      text-align: left;
      white-space: pre-wrap;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      margin: auto;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 5px solid transparent;
    }

    &-top:after {
      top: auto;
      border-bottom: none;
      border-top-color: $dgray6;
    }
    &-left:after {
      left: auto;
      border-right: none;
      border-left-color: $dgray6;
    }

    &-right:after {
      right: auto;
      border-left: none;
      border-right-color: $dgray6;
    }

    &-bottom:after {
      bottom: auto;
      border-top: none;
      border-bottom-color: $dgray6;
    }
  }
  .header {
    // height: 0.05rem;
    // background: #ccc;
    text-align: left;
    /* padding: 0rem 1rem; */
    margin: 1rem 2rem 0rem;

    .text-holder {
      background: #fff;
      /* height: 2rem; */
      /* display: inline-block; */
      /* padding: 0 0.5rem; */
      color: #999;
      font-size: 1.1rem;
      font-weight: 300;
      /* margin-top: -1rem; */
      vertical-align: middle;
      /* text-transform: uppercase; */
    }
    &.invalid {
      background-color: $mainred;
      .text-holder {
        color: $mainred;
      }
    }
  }
}
.uif-modal-window {
  .header {
    .icon-svg {
      color: $dblue1;
    }
  }
}

.merge-icons { // вынес из App, тк иконки еще импользуеются в модальных окнах, которые за  пределами App
  display: inline-block;
  position: relative;
  svg {
    z-index: 1;
  }
  .lock-icon {
    // background: $dviolet1;
    position: absolute;
    right: -15%;
    bottom: -15%;
    z-index: 2;
    /* background: #a26dc9; */
    // border-radius: 50%;
    width: 65%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;

    .lock {
      display: block;
      height: 100%;
      width: 100%;
      stroke: #fff;
      fill: $dred1;
      paint-order: stroke;
      stroke-width: 1rem;

      &.my-lock {
        fill: $dgreen1;
      }
      &.black {
        fill: $dgray4;
      }
    }
  }
  .validate-icon {
    // background: $dviolet1;
    position: absolute;
    right: -15%;
    bottom: -15%;
    z-index: 2;
    /* background: #a26dc9; */
    // border-radius: 50%;
    width: 65%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;

    .validate {
      display: block;
      height: 100%;
      width: 100%;
      stroke: #fff;
      fill: $green;
      paint-order: stroke;
      stroke-width: 1rem;

      &.in-progress {
        fill: $dorange1;
      }
      &.black {
        fill: $dgray4;
      }
    }
  }
}
@import "./core/components/css";

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
