.commit-card {
  &.current {
    border-color: $mainblue !important;

    .left {
      .date-time {
        background: $mainblue;
        color: $white;
      }
    }
  }
  overflow: hidden;
  svg {
    margin: 0;
    padding: 0;
    margin: 0 0.25rem;
    font-size: 1rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
  margin: 0.5rem;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 0.25rem;
  border: 0.0625rem solid $gray4;
  
  .left {
    width: 10rem;
    position: relative;
    text-align: left;
    padding: 0;
    font-size: 0.875rem;
    color: $dgray1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    
    .date-time {
      font-size: 0.75rem;
      display: block;
      background: $gray3;
      height: 1.25rem;
      margin-bottom: .25rem;
            
      padding: 0.25rem 0.5rem 0.25rem 0;
      border-radius: 0 0 0.25rem 0;
    }
    .space {
      flex: 1;
    }
    .commit, .user {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      span {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .commit-message {
    flex: 1;
    text-align: left;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    color: $dgray2;
    display: flex;
    flex-direction: row;
  }
  .right {
    display: flex;
    flex-direction: column;
    
    .ais-button {
      align-items: center;
      svg{
        margin-right: .25rem;
      }
    }
  }

  .go-to-commit-button{
    color: $mainblue!important;
    &:hover{
      color: $mainblue!important;
      font-weight: 600;
      font-size: 1.125rem;
    }
  }
}