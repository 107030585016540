.filter-form {
  &.fill {
    flex: 1;
    overflow: auto;
  }
  
  &-body {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
    color: $dgray1;
    text-align: left;
    
    &-item{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      // overflow: hidden;
      
      &:first-child {
        margin-top: -0.5rem;
      }
      &-clear {
        width: 2rem;
        height: 2rem;
        &>svg {
          font-size: 1.5rem;
          padding-left: .5rem;
          cursor: pointer;
        }
      }
      
    }

    &-separator {
      margin: 1rem 0 0 0;
      border-top: 0.0625rem dotted $gray6;
    }
  }

  @import "./form-title.scss";
}