@import "variables";
$text: $gray80;
$finishedText: $white;
$border: $gray80;
$inactiveNode: $gray7;
$statusComplete: #64aa43;
$statusFinish: $mainblue;

.react-flow__node {
  border-radius: 0.25rem;
  overflow: hidden;
}
.react-flow__handle{
  // display: none;
  opacity: 0;
}

.react-flow.workflow-diagram {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.react-flow__edge.animated path {
  stroke-dasharray: none;
  stroke: $mainblue;
}

.workflow-node {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  min-width: 10rem;
  max-width: 20rem;
  background-color: $white;
  font-size: 0.75rem;
  color: $text;
  .uif-components-button {
    height: 2rem;
  }
  .buttons-holder {
    display: flex;
    justify-content: center;
  }
  .status-info-wrapper {
    max-height: 10rem;
    overflow-y: auto;
    padding: 0.25rem;
  }
  &.target {
    .status {
      background-color: $mainblue;
      color: $white;
    }
  }
  &.incomplete {
    .status-info-wrapper {
      display: none;
    }
  }
  .status-info {
    padding: 0px 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .status-info:last-child {
    padding-bottom: 10px;
  }
  .status {
    font-size: 1.5rem;
    text-align: center;
    padding: 10px;
    overflow: hidden;

    &.complete {
      background-color: $statusComplete;
      color: $white;
    }
  }
  .status-time {
    padding-bottom: 5px;
  }
  .users {
    display: flex;
    justify-content: space-between;

    .user-image-avatar {
      width: 2rem;
      height: 2rem;
    }
  }
  &.inactive {
    background-color: $inactiveNode;
    color: $white;
  }
  &.finish {
    .status {
      &.complete {
        background-color: $statusFinish;
      }
    }
  }
}
