.issue-attributes {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &-title {
    // color: $gray8;
    color: $dviolet2;
    font-size: .875rem;
    text-align: left;
  }

  &-body {
    flex:1;
    margin-left: 1rem;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-field {
    z-index: 1!important;
    .label {
      margin: 0!important;
      padding: 0!important;
      color: $gray8;
      height: 2rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .ais-input-row {
      padding: .5rem 0;
      justify-content: flex-start;
      // align-items: center;
      align-items: flex-start;
      .ais-input {
        font-size: .875rem;
        text-align: left;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 0 2.2rem;
        &-div {
          overflow: hidden;
        }
      }

      .ais-input-div {
        input.ais-input {
          margin: 0 0 0 2.2rem!important;
          // padding: 0.5rem!important;
        }
      }
    }

  }
}
