.form-title {
  display: flex;
  flex-direction: row;
  height: 2rem;
  align-items: center;
  padding: 0 1rem 0 0.5rem;
  fill: $dgray1;
  color: $dgray1;  

  &-text {
    margin-left: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.875rem;
    font-weight: 600;
  }

  svg {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.25rem;
  }

  &-spacer {
    flex: 1 1;
    height: 0.0625rem;
    background: $gray6;
  }
}
