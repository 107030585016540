@import "./add-kind.scss";
@import "./kind-group.scss";

.ais-field-container.boolean{
  .ais-field {
    border-width: 0;
  }
}

.uif-components-button.action {
  color: $dviolet2 !important;
  // background-color: transparent !important;
  background-color: $dviolet2 !important;
  color: white !important;
  justify-content: flex-start;
  height: 2rem;
  font-size: 0.875rem;

  &:hover{
    color: white !important;
  }
  &:before {
    content: none;
  }

  &:not(.disabled):not(.outline):hover {
    box-shadow: none;
    background-color: $dviolet3 !important;
  }
}

.uif-components-button.negative {
  height: 2rem;
  font-size: 0.875rem;
  color: $dred3 !important;
  background-color: transparent !important;

}

