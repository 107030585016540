@import "variables.scss";
.viewer {
  display: flex;
  flex-direction: column;
  flex: 1;

  .viewer-content {
    flex: 1;
    flex-direction: column;
    display: flex;
    background: #fff;
    justify-content: center;
    overflow: auto;
    position: relative;

    &.image {
      background-image: linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.03) 25%,
          transparent 25%
        ),
        linear-gradient(-45deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.03) 75%),
        linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.03) 75%);
      background-size: 1rem 1rem;
      background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
    }

    iframe {
      flex: 1;
    }

    .file-content{
      flex: 1 1;
      flex-direction: column;
      display: flex;
      justify-content: center;
      overflow: auto;
      position: relative;
      // &.image{
      //   background-image: linear-gradient(
      //       45deg,
      //       rgba(0, 0, 0, 0.03) 25%,
      //       transparent 25%
      //     ),
      //     linear-gradient(-45deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%),
      //     linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.03) 75%),
      //     linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.03) 75%);
      //   background-size: 1rem 1rem;
      //   background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
      // }
      img{
        transition: transform 0.25s ease;

        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .viewer-content-image, .viewer-content-download {
      margin: 0 auto;
    }

    .viewer-content-image{
      width: auto;
    }

    .header {
      margin: 0.5rem;
      height: 2rem;
      background: none;
      text-align: center;
    }
  }
}

.mathml-svg {
  object {
    background-color: #fff;
  }
  &-error {
    color: $mainred;
    padding: 1.5rem;
  }
  &-content {
    margin: 0.5rem;
  }
}

svg.preloader {
  margin: 1rem auto;
  display: block;
}
