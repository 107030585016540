.journal-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  vertical-align: middle;
  .avatar {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    // padding: .125rem;
    border-radius: 0.25rem;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border: .0125rem solid $mainorange;
    display: inline-block;
  }

  .user-name, .user-undefined{
    padding-left: .5rem;
    font-size: .875rem;
    color: $dorange1;
    display: inline;
    vertical-align: super;
    text-align: left;

  }
  .user-name {
    color: $dorange1;
  }

  .user-undefined {
    color: $mainred;
  }
}
