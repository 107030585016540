$border-width: 0.0625rem;
$border-color: $dgrayblue2;

.message-item{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  margin: 0;
  padding: .5rem 0;

  border-bottom: $border-width solid $border-color;

  color: $dgrayblue2;

  &-num {
    align-self: flex-start;
    width: 2rem;
    text-align: left;
    // margin: auto .5rem;
    margin: .5rem;
  }
  &-data{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-info, &-payload {
    flex: 1;
    text-align: left;
    margin: auto 0.5rem;
  }

  &-info {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-action {
    width: 8rem;
    .date-time {
      color: $dgrayblue1;
      font-size: .75rem;
    }

    .action {
      font-size: 1rem;
      text-transform: uppercase;
      color: $dgrayblue1;
      line-height: 1.5rem;
    }
  }

  &-object {
    flex: 1;
    .uid {
      color: $dgrayblue1;
      font-size: .75rem;
    }

    .class {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &-user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    .avatar {
      width: 2rem;
      height: 2rem;
      flex: none;
      overflow: hidden;
      border-radius: .25rem;
      border: $border-width solid $border-color;
    }

    .user-name {
      flex: 1;
      text-align: left;
      margin: auto .5rem;
      font-size: .875rem
    }
  }

  &-payload{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    padding: .75rem 0;
    border-radius: .25rem;
    // border: $border-width solid $border-color;
    // overflow: hidden;

    .payload-title {
      user-select: none;
      height: 1rem;
      flex: 1;
      position: relative;
      font-size: 1rem;
      border-top: $border-width dotted $border-color;

      &-text {
        color: $mainblue;
      }
      &-body{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        background-color: $white;
        position: absolute;
        left: 1rem;
        top: -.5rem;
        padding: 0 .5rem;
      }

      &-icon {
        margin-left: .5rem;
      }
    }

    .payload-body {
      flex: 1;
      padding-top: 1rem;
      // background-color: #f5f2f0;
      background: transparent;
      border-left: $border-width dotted $border-color;
      border-right: $border-width dotted $border-color;
      border-bottom: $border-width dotted $border-color;
      pre{
        flex: 1;
        margin: 0;
        display: grid;
        // border-radius: .25rem;
        code {
          // flex: 1;
          width: 100%;
          overflow: auto;
        }
      }
    }
  }
}
