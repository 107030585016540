.repo-branches-tags {
  border-radius: .375rem;
  border: .0625rem solid $gray6; 
  padding: 0 .25rem;
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  .repo-dropdown {
    // padding-top: .25rem;
    // width: 1rem;
    // height: 1rem;
    cursor: pointer;
  }

  label {
    font-weight: 400;
  }

  &-menu {
    position: absolute;
    z-index: 5;
    top: 2.25rem;
    right: 0;
    background-color: $gray2;
    border: .0625rem solid $gray6; 
    border-radius: .25rem;
    min-width: 15rem;

    // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09), 0px 1px 1px rgba(0, 0, 0, 0.15);
    // box-shadow: 0 0.125rem 0.25rem -0.15rem rgba(0, 0, 0, 0.5);
    // box-shadow: 0 .875rem 2rem rgba(0,0,0,0.25), 0 .75rem .75rem rgba(0,0,0,0.22);
    box-shadow: 0 .875rem 4rem rgba(0,0,0,0.25), 0 4rem 4rem rgba(0,0,0,0.22);

    // &:before, &:after {
    //   content: ''; 
    //   position: absolute;
    //   right: .125rem; 
    //   top: -1rem;
    //   border: .5rem solid transparent;
    //   border-bottom: .5rem solid $gray6;
    // }

    // :after {
    //   border-top: 1.5rem solid $white;
    //   bottom: 1rem; 
    // }

      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        right: .25rem;
        top: -.5rem;
        // border: .5rem solid $gray4;
      }

      &:before {
          border-right: .5rem solid transparent;
          border-bottom: .5rem solid $gray6;
          border-left: .5rem solid transparent;
      }

      &:after {
          border-right: .5rem solid transparent;
          border-bottom: .5rem solid $gray2;
          border-left: .5rem solid transparent;
          top: -.4375rem;
      }

    &.disabled {
      display: none;
    }

    &.show {
      display: block;
    }

    .title {
      font-size: .875rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .5rem;
      border-bottom: 0.0625rem solid $gray6;
      color: $dgray1;
      label{
        flex: 1;
        text-align: left;
        font-weight: 400;
      }
      .close-button {
        cursor: pointer;
        &-icon {
          font-size: 1.25rem;
        }
      }
    }
  }
  .body {
    min-height: 5rem;
    max-height: 20rem;
    overflow: hidden;
    display: flex;
    padding-top: .5rem;

    .tags-tab {
      font-size: .875rem;
      color: $dgray2;
    }
    .ais-tabs, .react-tabs {
      overflow: hidden;

    }
    
    .react-tabs__tab{
      &--selected {
        border-bottom: 0.0625rem solid $mainblue;
        .tags-tab {
          color: $mainblue;
        }
      }
    }
    
    .list-item{
      flex: 1;
      padding: .75rem 1rem ;
      border-bottom: 0.0625rem solid $gray6;
      color: $dgray1;
      cursor: pointer;
      font-size: .75rem;
      font-weight: 400;
      overflow: auto;
      &:hover {
        background-color: $gray4;
      }
      &:last-child{
        border:0;
      }
      &.active {
        font-weight: 600;
        color: $dgray2;
      }
    }
  }
}