.issue-editor {
  &-notes{
    background-color: $orange1!important;
    border: 0!important;
    // border: .0125rem solid $halforange !important;

    .toolbar-buttons-button {
      &.active {
        color:$mainorange!important;
        border-bottom: .0625rem solid $mainorange!important;
      }
    }
  }

  &-description {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    color: $dgray5;
    background-color: $grayblue2;
    border: .0125rem solid $grayblue4;
    text-align: left;

    padding: 1rem 2.5rem;
    border-radius: .25rem;

    &.invalid {
      border: .0625rem solid $mainred!important;
    }

    &-text {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      font-size: 1rem;
      white-space: pre-wrap;
      overflow-x: clip;
      // overflow-y: visible;

      textarea {
        flex: 1;
        font-size: 1rem;
        padding: .5rem;
        overflow: auto;
        border: .0625rem solid $gray4;
        border-radius: .25rem;
        background-color: $white;
        &:focus{
           outline: 0;
        }
      }
    }

    .toolbar {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      padding: .5rem 0 1rem 0;
      background: none;
      &-title{
        flex: 1;
        font-size: .875rem;
        color: $gray8;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &-buttons {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-end;

        &-button {
          // padding: .5rem;
          width: 2rem;
          height: 2rem;
          color:$gray7;
          cursor: pointer;
          font-size: 1.5rem;
          // margin: 0.25rem;
          svg {
            fill: currentcolor;
            height: 1em;
            width: 1em;
          }

          &.active {
            color:$mainblue;
            border-bottom: .0625rem solid $mainblue;
          }
        }
      }
    }
  }
}
