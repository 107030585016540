.schema-name{
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .label {
    color: $gray8;
    font-size: 0.875rem;
    font-weight: 400;
  }
  
  .buttons {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .ais-button {
      margin: 0;
      border: 0;
      &.disabled {
        background: none!important;
      }
    }
  }
}