.filter-status {
  &-label {
    text-align: left;
    font-size: .875rem;
    color: $gray8;
    line-height: 1rem;
    padding: .2rem 0 0 0 ;
    color: $dgray3;
  }

  &-toggles{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    margin-bottom: .5rem;
    &-toggle-button {
      // color: $dgrayblue1;
      color: $gray8;
      font-size: .875rem;
      line-height: 1rem;
      padding: 0.25rem .5rem .5rem .5rem;
      cursor: pointer;

      &.active {
        color: $mainblue;
        border-bottom: 0.0625rem solid $mainblue;
      }
    }
  }
}
