.coord-letter-number{
  &-prefix{
    // padding-top: .5rem;
  }
  &-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0.0625rem solid $dgray1;
    padding: 0.25rem;
    border-radius: 0.25rem;
    .ais-field-container.no-label{
      margin-top: 0;
    }
  }
}