@import "~@ais3p/ui-framework/colors.scss";

.uif-modal-window {
  &.login {
    min-width: 23rem;
    .header {
      .icon-svg {
        color: $dblue1;
      }
    }
  }
  .login-container {
    min-height: 8.5rem;
    .ais-field-container {
      &::after {
        content: '';
        display: block;
        height: .9375rem; 
      }
      &.error:has(.ais-field-hint)::after {
        content: none; 
      } 
    }
  }
  .login-workspace {
    .ais-field {
      min-height: 1rem;
      margin-top: 0;
    }
    .ais-boolean-container {
      width: 2rem;
    }
    .ais-boolean {
      height: 0.8rem;
      width: 1.4rem;
      .ais-switch {
        height: 0.55rem;
        width: 0.55rem;
      }
    }
    .label {
      font-size: 0.75rem;
    }
  }
}