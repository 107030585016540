@import "~@ais3p/ui-framework/colors.scss";
.wizard-tool-holder {
  flex-direction: column;
  width: 100%;
}
  .wizard-tool-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #fff;
    min-width: 20rem;
    .name-holder {
      min-width: 2rem;
      .ais-field-container {
        &::after {
          content: '';
          display: block;
          height: .9375rem; 
        }
        &.error:has(.ais-field-hint)::after {
          content: none; 
        } 
      }
    }
    .page-holder {
      .wizard-kind-view {
        .ais-field-container {
          &::after {
            content: '';
            display: block;
            height: .9375rem; 
          }
          &.error:has(.ais-field-hint)::after {
            content: none; 
          } 
        }
      }
    }
    
    .ais-field-label {
      color: #fff;
    }
    .kind-name {
      display: flex;
      font-weight: 100;
      font-size: 1.25rem;
      color: rgba(0, 0, 0, 0.25);
      flex-direction: row;
      align-items: center;

      .expander.big {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 0 0 0.25rem;
      }
    }
    .divider {
      height: 0.0625rem;
      background-color: rgba(0, 0, 0, 0.25);
      margin: 0.25rem 0;
    }

    .ais-input-holder .label {
      background: transparent;
      padding: 0.2rem 0;
      color: #000;
      font-size: 0.875rem;
      padding-bottom: 0.125rem;
    }

    .page-holder {
      flex: 1;
      overflow: auto;
      position: relative;

      .wizard-kind-view {
        padding-top: 1rem;
      }
    }


  .buttons-holder {
    flex-direction: row;
    display: flex;
  }
}