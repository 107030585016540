.schema-trace-link{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .125rem;
  margin: .25rem 0;
  background-color: $cyan1;
  border-radius: .25rem;
  align-items: center;
  justify-content: center;
  &>svg{
    font-size: 2rem;
    color: $gray8;
  }

  &-title{
    align-self: center;
    flex: 1;
    text-align: left;
    font-size: .875rem;
    color: $gray8;
  } 
}