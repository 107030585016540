.render-item-container{
  flex: 1;
}
.repo-node{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.selected {
    svg{
      color: $mainblue;
    }
  }
  &-name{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // &.repo-mode{
    //   // flex:none;
    //   // width: 15rem;
    // }
    .node-icon{
      width: 1.5rem;
      height: 1.5rem;
      &:hover{
        color: $mainblue;
      }
    }
    span {
      flex: 1;
      text-align: left;
    }
  }

  &-info{
    display: flex;
    flex-direction: row;

    // border: 1px solid red;

    width: 50rem;
    max-width: 50rem;
    min-width: 50rem;
    overflow: hidden;
    .repo-node-info-cell{
      // border: 1px solid red;
      min-width: 3rem;
      text-align: center;
      margin: auto;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 .25rem;
      display: table-cell;
      &.commit {
        width: 3rem;
      }
      &.author {
        width: 5rem;
      }

      &.commit-date {
        width: 9rem;
      }
      &.size {
        width: 7rem;
      }

      &.commit-message{
        text-align: left;
        flex: 1;
        p {
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
          padding: 0;
          max-width: calc(50vw - 1.5rem);
        }
      }
    }
  }
}