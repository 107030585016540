@import "variables.scss";

.no-content, .no-records, .empty-list {
  margin: 1rem;
  color: $gray8;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 300;
}

@import "./analyzer";
@import "../../components";
@import "./dropdown-menu";

