@import "variables";
$active: $mainblue;
$finished: #999999;
$timecolor: $halfblue;
$text: $dgray2;

.state-card-container {
  border: 1px solid $gray6;
  border-radius: .25rem;
  background-color: $white;
  padding: 1rem 1.5rem;
  min-width: 8rem;
  display: flex;
  flex-direction: column;
  .state-card-content {
    display: flex;
    justify-content: space-between;
  }
  .state-time {
    line-height: 1rem;
    font-size: .75rem;
    color: $timecolor;
    // padding-left: .5rem;
  }
  .state-title {
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    color: $active;
    // padding: 0.5rem;
    .state-title__container {
      display: flex;
      justify-content: space-between;
    }
    .state-title__icon {
      font-size: 1.5rem;
    }
    .state-title__name {
      line-height: 2rem;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      position: relative;
      .text-content {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }
  &.finished {
    .state-title {
    color: $text;
    .text-line {
      border-bottom: .5rem solid $gray4;
    }
    }
    .state-time {
      color: $finished;
    }
  }
  .state-final-text {
    margin-bottom: 0;
  }
  .state-btn__container {
    display: flex;
    padding-top: 2rem;
    flex-wrap: wrap;
    justify-content: end;
  }
  .users {
    display: flex;
    justify-content: flex-end;
    margin-left: .5rem;
    align-items: flex-end;
    overflow: hidden;
    .user-image {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: .5rem;
      .user-image-avatar {
        text-align: end;
        min-width: 2.5rem;;
      }
    }
    .user-name {
      line-height: 1rem;
      font-size: .75rem;
      color: $text;
      align-self: flex-end;
      padding-bottom: .3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      &.with-image {
        padding-bottom: 0rem;
      }
    }
  }
}