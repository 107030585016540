@import "variables";

$fontcolor: $dcyan1;
$darkfont: $dcyan3;
$backcolor: $cyan1;
$deepbackcolor:$cyan2;
$bordercolor: $cyan3;
$cursorbackground: $cyan3;

.sub-items {
  .connection-tool-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .preload-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.1);

      .preloader {
        stroke: $dcyan1 !important;
      }
    }

    .connection-tracker {
      flex: 1;
      display: flex;
      max-height: 100%;
      flex-direction: column;
      overflow: hidden;  

      .object-holder {
        .target-object {
          background: $deepbackcolor;
          border-bottom-color: $bordercolor;
        }
      }

      .lock-icon {
        svg.lock {
          display: none;
        }
      }

      .list-holder {
        flex-direction: column;
        display: flex;
        overflow: hidden;
        justify-content: stretch;
        flex-grow: 1;
        .items-holder {
          flex: 1 1;
          overflow: auto;
          background: $backcolor;

          .header {
            margin: 1rem;
            text-align: center;

            .text-holder {
              background-color: transparent;
            }
          }

          @import "../../components/css/";
        }
      }
    }

    .target-info {
      &:hover {
        color: $darkfont;
      }
    }
  }
}
