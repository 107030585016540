@import "variables";
$contentColor: $gray2;
$textColor: $dgray2;
$headerColor: $gray6;

.workflow-sidepanel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .preload-wrapper {
    margin: 2rem auto;
  }
  .preloader {
    stroke: $mainblue !important;
  }
  .object-holder {
    .lock-icon {
      svg.lock {
        display: none;
      }
    }
    .target-object {
      background: $headerColor;
      border-bottom-color: $gray7;
    }
  }
}
.workflow-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: $contentColor;
  color: $textColor;
  .item-content {
    .header {
      margin: 1rem;
      text-align: center;

      .text-holder {
        background: transparent;
      }
    }
  }
}