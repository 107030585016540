@import "~@ais3p/ui-framework/colors.scss";
.plus-menu-button {
  padding: 0.3rem .3rem;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.25rem;
  transition: all .2s linear;

  &:hover {
    background-color: $dgray5;
    color: $mainblue;
    transition: all .2s linear;
  }

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}