.commits-view {
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  .commits{
    flex: 1;
    overflow: auto;
  }
  #observer {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5rem;
  }
  @import "./commit-card.scss";
}