.message-list {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 .5rem;

  .wating-data {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.25rem;
    color: $gray7;
    transform: translate(-50%, -50%);
  }

  @import "./message-item";
}
