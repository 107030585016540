.issues-table-columns-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // flex: 1;
  position: relative;
  padding: .75rem .25rem 0 .25rem;

  &:after {
    content: "";
    border-right: .0625rem solid $gray4;
    position: absolute;
    right:0;
    top: 2.25rem;
    bottom: .5rem;
  }

  &:last-child{
    &:after {
      border-right: 0;
    }
  }

  .title {
    font-size: .75rem;
    color: $gray8;
    margin-bottom: .25rem;
  }

  .groups{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .group-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: .125rem .5rem .5rem .5rem;

      // margin: 0 1rem;
      &-checkbox{
        font-size: .875rem;
        color: $dgray2;
        padding: .25rem 0;
        display: flex;
        label{
          display: inline-flex;
          align-items: center;
          user-select: none;
          position: relative;
          &:before {
            content: "";
            display: inline-block;
            width: .75rem;
            height: .75rem;
            border: 0.0625rem solid $dgray2;
            border-radius: 0.125rem;
            margin-right: 0.5rem;
            cursor: pointer;
          }
        }
        input[type="checkbox"]{
          position: absolute;
          z-index: -1;
          opacity: 0;
          &:not(:disabled):not(:checked)+label:hover::before {
            border-color: $mainblue;
          }
          &:not(:disabled):checked+label::before {
            border-color: $mainblue;
            opacity: 1;
            content: "\2713";
            font-size: .75rem;
            color: $mainblue;
            line-height: .75rem;
          }
        }
      }
    }
  }


}
