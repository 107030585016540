.search-issues {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 1rem;
  // padding-bottom: .5rem;
  // border-bottom: 0.0625rem solid $halfgreen;

  border-radius: .25rem;
  padding: 0.75rem;
  border: 0.0625rem dashed $halfgreen;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
  }

  &-preloader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left:0;
    top:0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: .1);
    svg{
      width: 3rem;
      height: 3rem;
    }    
  }

  &-list {
    // flex:1;
    padding: 0 .5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 20rem;
    overflow-y: auto;
    overflow-x: hidden; 
    @import "./search-item.scss";
  }
  &-controls{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: .5rem;
  }
}