@import "variables.scss";

.check-list {
  &-tool{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    @import "../../components/index.scss";
  }

  &-preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-parameters {
    // flex: 1;
    width: 100%;
    background-color: $gray3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom: 0.0625rem solid $gray4;
    &-controls{
      overflow: hidden;
      display: flex;
      flex-direction: row;
      button{
        margin: 0.25rem;
        height: 2rem;
        &:last-child{
          margin-left: 0;
        }
      }
    }
    .object-holder{
      flex: 1;
      overflow: hidden;
      .target-object{
        border-width: 0;
      }
    }
    .divider{
      width: 2rem;
      svg {
        height: 2rem;
        width: 2rem;
      }
    }
    
  }

  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: $white;
    overflow: hidden;
    position: relative;
    width: 100%;
    &-data{
      display: flex;
      overflow: auto;
      flex: 1;

      .frs-num-content {
        cursor: pointer;
        color: $mainblue;
      }

      .boolean-value {
        &>div{
          cursor: pointer;
        }
      }

      .string-value-content{
        cursor: pointer;
        width: 100%;
        min-height: 1rem;
      }

      // @import "../../components/index.scss";
    }

    &-controls {
      display: flex;
      align-items: center;
    }
  }
}


