.current-revision {
  font-size: 0.75rem;
  height: 1.5rem;
  padding: 0 0.25rem 0 0.5rem;
  background: #eee;
  color: #272822;
  border-top: 0.0625rem solid #ddd;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  label, span {
    margin: 0 .25rem;
  }
  p {
    overflow: hidden;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  svg {
    margin: 0;
    padding: 0;
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}
