@import "variables";

.k-and-a-sidepanel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: $violet1;
  position: relative;

  .preload-wrapper {
    // margin: 2rem auto;
    position: absolute;
    left:0;
    top:0;
    right: 0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.1);
  }

  .preloader {
    stroke: $dviolet2 !important;
  }

  .object-holder {
    background: $violet2;

    .lock-icon {
      svg.lock {
        display: none;
      }
    }

    .target-object {
      background: $violet2;
      border-bottom-color: $violet3;
    }
  }

  .k-and-a-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: $violet1;
    
    .k-and-a-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: .25rem .5rem;
      &.top {
        padding-bottom: 0;
      }
      &.bottom {
        padding-bottom: .5rem;
      }
    }

    .item-content {
      .header {
        margin: 1rem;
        text-align: center;

        .text-holder {
          background: transparent;
        }
      }
    }

    .divider {
      margin: 1rem 0 0;
      height: 0;
      border-bottom: 0.0625rem dashed $dviolet1;
    }
  }

  @import "../../components/css";
}
