.condition-picker {
  position: fixed;
  // position: absolute;
  // bottom: 2rem;
  // left:-1rem;
  background-color: $white;
  padding: .25rem 0;
  border-radius: .25rem;
  border: 0.0625rem solid $gray5;
  z-index: 5;
  // z-index: 300!important;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: .75rem;
    bottom: -.5rem;
    // border: .5rem solid $gray4;
  }

  &:before {
    border-right: .5rem solid transparent;
    border-top: .5rem solid $gray5;
    border-left: .5rem solid transparent;
  }

  &:after {
    border-right: .5rem solid transparent;
    border-top: .5rem solid $white;
    border-left: .5rem solid transparent;
    bottom: -.4rem;
  }

  &.bottom {
    top: 2rem;
    bottom: unset;
    &:before, &:after {
      top: -.5rem;
      bottom: unnset;
    }

    &:before {
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid $gray5;
      border-left: .5rem solid transparent;

      border-top: 0;
    }

    &:after {
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid $white;
      border-left: .5rem solid transparent;
      top: -.4rem;
      border-top: 0;
    }
  }

  &-item {
    // margin: .5rem .25rem;
    padding: .5rem .75rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    border-bottom: 0.0625rem solid $gray5;
    &:last-child{
      border: 0;
    }
    &.active {
      background-color: $gray3;
    }
    label {
      font-weight: 600;
      width: 1.5rem;
      text-align: center;
    }
    span {
      padding-left: .5rem;  
    }
    &:hover {
      background-color: rgba(0,0,0, 0.1);
    }
  }
}