.issue {
  // font-family: Helvetica, Roboto, Arial, sans-serif;
  font-family: Arial, sans-serif;
  background: $white;
  margin: .5rem 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09), 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: .25rem;

  &:first-child {
    margin-top: 0;
  }

  &-dnd{
    flex: 1;
    display: flex;
    &-empty {
      flex: 1;
      height: 5.5rem;
      background-color: $grayblue3;
      border: .0625rem solid $grayblue4;
      &-wrapper {
        width:100%;
      }
    }
  }

  &-item{
    &.read-only{
      cursor: not-allowed;
    }

    cursor: move;

    &-preloader{
      position: absolute;
      top: 10%;
      left: 40%;
      transform: translate(-50%,-50%);
    }

    .drag-source-layer {
      flex: 1;
    }

    &-body {
      padding: .75rem;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;

      &.loading {
        background: rgba(0,0,0, .05);
      }
    }


    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &-title {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      // cursor: pointer;
      display: inline-block;
      .drag-source-layer {
        flex:unset;
      }
      &-num{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #B8C5CA; //$halfgrayblue;
        .issue-icon{
          margin-left: -.25rem;
          // color: $dgrayblue1;
        }
        &-id {
          flex: 1;
          margin-left: .125rem;
        }
      }

      &-subject {
        color: #535E62;//$dgrayblue2;
      }
    }

    &-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin: .25rem 0;
      font-size: .875rem;

      &-blocks {
        padding: 0.25rem 0.5rem;
        border-radius: .25rem;
        margin: .5rem .5rem 0 0;
        border-width: .0625rem;
        border-style: solid;
        &.status{
          color: $dblue2;
          background-color: $grayblue3;
        }

        &.priority {
          color: $dgreen2;
          background-color: $green1;
        }
      }
    }

    &-user{
      width: 2.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      &-photo {
        flex: none;
        width: 2rem;
        height: 2rem;
        border-radius: 0.5rem;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        border: .0125rem solid $blue3;
      }
    }
  }
}
