.schema-parameters{
  padding: 0;
  text-align: left;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  &-body{
    flex: 1;
    overflow: hidden;
    display: flex;
    .label {
      color: $gray8;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .buttons-holder {
    display: flex;
    margin-top: .75rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    border-top-width: 0;
  }
}