@import "variables";

.workflow-content {
  .workflow-content__list {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 0.5rem;
    margin: 0;
    list-style-type: none;
  }
  .workflow-title {
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 0;
  }
  .workflow-text {
    font-size: .875rem;
    font-weight: 400;
    margin: 0;
    padding: .5rem 0;
  }
  .workflow-empty {
    display: inline-block;
    color: #999;
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: .5rem;
    text-align: center;
  }
  .diagram-btn-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: end;
  }
}
.react-flow__attribution {
  display: none;
}
.react-flow__edge-path {
  stroke-width: 2;
}
.workflow-diagram {
  .react-flow__panel {
    margin: 0;
  }
  .ais-field-container.boolean .ais-field {
    margin: 0.5rem;
    margin-right: 1rem;
  }
  .ais-boolean-div .ais-boolean-container .ais-boolean {
    height: 1rem;
  }
}