.search-issues-item{
  display: flex;  
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-bottom: .0625rem solid $green3 ;
  padding: .25rem 0;
  
  &-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    font-size: .875rem;
    color: $dgray2;
    overflow: hidden;

    .title-prefix {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 0.5rem;
      color: $gray8;
      white-space: nowrap;
      label {
        cursor: pointer;
      }
    }

    span {
      // white-space: nowrap;
      line-height: 1rem;
      max-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-controls {
    padding-left: .5rem;
    width: 2rem;
  }
}