.add-item-line {
  display: flex;
  flex-direction: row;
  background-color: $cursorbackground;
  // min-height: 3rem;
  padding: .25rem 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.no-restriction, .empty-rules {
    font-size: 0.875rem;
    color: $dcyan2;
    width: 100%;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  &-wrapper{
    // min-height: 3rem;
    padding: .25rem 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: center; 
  }

  &-controls{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .uif-components-button.action {
    background: $dcyan1 !important;
    
    &:not(.disabled):not(.outline):hover {
      box-shadow: none;
      color: white !important;
      background-color: $dcyan2 !important;
    }
  }

  .item-drop-target {
    flex: 1;
    position: relative;
    display: flex;
    font-size: 0.875rem;
    color: $dcyan2;
    justify-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    border: 0.0625rem solid $maincyan;
    border-radius: 0.25rem;
    .ais-object{
      overflow: hidden;
    }

    &-error{
      position: absolute;
      z-index: 3;
      border: 1px solid $mainred;
      background-color: $red1;
      color: $mainred;
      text-align: center;
      font-size: .875rem;
      padding: .25rem;
      border-radius: .25rem;

      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        right: calc(50% - 8px);
        bottom: -8px;
      }

      &:before {
          border-right: 8px solid transparent;
          border-top: 8px solid $mainred;
          border-left: 8px solid transparent;
      }

      &:after {
          border-right: 8px solid transparent;
          border-top: 8px solid $red1;
          border-left: 8px solid transparent;
          bottom: -7px;
      }
    }

    &.no-object{
      border: 0.125rem dashed $maincyan;
    }

    .dnd-target {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: center;

      .placeholder{
        padding: .25rem;
        line-height: 1.5rem;
        &-text{
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
        }
        &-label{
          margin-right: .25rem;
        }
        &-item{
          overflow: hidden;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          svg{
            font-size: 1.5rem;
          }
        }
      }

      &.over-child {
        &.can-drop {
          background-color: $maincyan;
        }
        &.can-not-drop {
          color: $mainred;
        }
      }
    }

    .object {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .125rem;
      font-size: 1rem;
      font-weight: 100;
      overflow: hidden;
      flex: 1;

      .ais-object {
        border: none;
        color: $fontcolor;
        max-width: 100%;
        flex: 1;
        text-align: left;
        svg {
          fill: $fontcolor;
        }
        &:hover {
          background-color: transparent;
        }
      }

      svg.delete {
        cursor: pointer;
        font-size: 1.5rem;
        &:hover {
          color: $mainred;
        }
      }
    }
  }
}