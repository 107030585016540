.repo-info{
  background-color: $gray3;
  border-bottom: 0.0625rem solid $gray4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 .5rem;
  min-height: 2.25rem;
  .repo-type{
    text-transform: uppercase;
  }
  .repo-icon {
    width: 1.75rem;
    height: 1.75rem;
  }
  &-item{
    margin-left: .75rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    label{
      font-weight: 400;
      margin-right: .25rem;
    }

    span{
      font-weight: 400;
      color: $gray8;
    }
  }
}