.header-panel {
  height: 1.5rem;
  background-color: $gray4;
  line-height: 1.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  color: $gray8;

  &-text {
    flex: 1;
    font-weight: 500;
    font-size: 0.75rem;
    text-align: left;
    margin: 0 .5rem;
  }

  svg{
    height: 1.5rem;
    width: 1.5rem;
  }
}
