.issue-related-issues-item{
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  &.current-issue{
    .title-prefix {
      font-weight: 600;
    }
  }
  &.issue-closed{
    label, span{
      text-decoration: line-through; 
    }
  }
  &-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    font-size: .875rem;
    color: $dgray2;
    overflow: hidden;

    .title-prefix {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 0.5rem;
      color: $gray8;
      white-space: nowrap;
      label {
        cursor: pointer;
      }
    }

    span {
      // white-space: nowrap;
      line-height: 1rem;
      max-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-controls {
    padding-left: .5rem;
    // width: rem;
  }
}