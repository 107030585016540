$color-mainblue: #01abfb;

.handle-resizer{
  z-index: 10;
  border: 0.0625rem solid $grayblue4;
  &.resizing {
    border: 0.125rem solid $color-mainblue;
  }
  &-horizontal {
    cursor: ew-resize;
  }
  &-vertical {
    cursor: ns-resize;
  }
}
