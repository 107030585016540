.status-column {
  width:  20rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  margin: 0 .5rem .5rem .5rem;

  &-header {
    background-color: $grayblue4;
    color: $dgrayblue2;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    text-align: left;
    padding: .5rem 1rem;
  }

  &-body {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $white;
    padding: .5rem .75rem 1rem;
    background-color: $grayblue2;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;

    .drop-target-layer {
      width: 100%;
      display: flex;
    }
  }
}
