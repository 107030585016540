@mixin marker{
  width: .125rem;
  content: "";
  top:0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
@mixin end-marker{
  @include marker;
  right: 0;
  background-color: $mainred;
}

@mixin end-marker-overdue{
  @include marker;
  right: 0;
  background-color: $mainblue;
}

@mixin start-marker{
  @include marker;
  left: 0;
  background-color: $maingreen;
}


.timeline {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  // margin: 0 -1.25rem;

  &-points {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0 1.25rem;

    &-up, &-down {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      position: relative;
      &.overdue:after{
        @include end-marker-overdue;
      }

      &:after{
        @include end-marker;
      }
    }

    &-up {
      flex-wrap: wrap-reverse;
    }

    &-down {
      &:before{
        @include start-marker;
      }
    }
  }

  &-point {
    text-align: left;
    display:flex;
    flex: 1;

    &.start{
    }
    &.now, &.overdue{
      padding-right: 0;
      text-align: left;
      .title {
        white-space: pre-line;
      }
    }

    &.reverse {
      padding-left: 0;
      text-align: right;
      // padding-right: 55%;
      flex-direction: row-reverse;
    }

    &.end, &.end-overdue {
      text-align: right;
      z-index: 2; // необходимо, чтобы перекрыть маркер
      background-color: $white;
    }


    .title {
      white-space: nowrap;
      font-size: .875rem;
      font-family: Arial;
      font-weight: 400;
      padding: 0 .25rem;
      line-height: 1.5rem;
      flex: 1;
      z-index: 1;

      .date {
        color: $dgray5;
      }

      .postfix {
        color: $gray7;
      }
    }

    .marker {
      width: .125rem;
      position: absolute;
      z-index: 0;

      &.start{
      }

      &.now, &.overdue {
        top: 1.25rem;
        bottom: -.525rem;
        z-index: 2;
      }

      &.now {
        background: $mainblue;
      }

      &.overdue {
        background: $mainred;
      }

      &.end{
      }
    }
  }

  &-slider {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    margin: 0 1.25rem;
    &:before{
      @include start-marker;
    }
    &:after{
      @include end-marker;
    }

    &.overdue:after{
      @include end-marker-overdue;
    }

    &-gone {
      height: .5rem;
      background-color: $green2;
      border-top: .0625rem solid $halfgreen;
      border-bottom: .0625rem solid $halfgreen;
    }

    &-left {
      flex: 1;
      height: .5rem;
      background-color: $grayblue2;
      border-top: .0625rem solid $grayblue4;
      border-bottom: .0625rem solid $grayblue4;

      &.overdue {
        background-color: $red2;
        border-top: .0625rem solid $halfred;
        border-bottom: .0625rem solid $halfred;
      }

      &.end-undefined {
        border-top: .0625rem solid $grayblue4;
        border-bottom: .0625rem solid $grayblue4;
        background: repeating-linear-gradient(135deg, $grayblue4, $grayblue4 0.25rem, $grayblue2 0, $grayblue2 0.5rem);
      }
    }
  }

  &-timeaxis {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    line-height: 1.25rem;
    overflow: visible;

    &-value {
      max-width: 2.5rem;
      color: $gray7;
      font-size: .875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      overflow: visible;
      // white-space: pre-line;

      &:first-child{
        align-items: flex-start;
        span {
          margin-left: .875rem;
        }
      }

      &:last-child{
        align-items: flex-end;
        span {
          padding-right: .875rem;
        }
      }
    }

    &.end-undefined {
      .timeline-timeaxis-value:last-child{
        align-items: center;
        span {
          padding-right: 0;
        }
      }
    }
  }
}
