.journal-list{
  flex: 1;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background: $orange1;

  &-preloader{
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    flex-direction: center;
    align-items: center;
    z-index: 10;
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    top:0;
    background: rgba(0, 0, 0, .1);
    svg {
      stroke: $mainorange!important;
    }
  }

  &-pagination{
    background: $orange2;
    border-color: $orange3;
    color: $dorange1;
    input {
      border-color: $dorange1;
    }

    .pagination-page-size {
      color: $dorange1;
      .active {
        color: $dorange2;
      }
    }

    .pagination-button {
      color: $dorange1;
      &.disabled {
        color: $halforange;
      }
    }
  }
  &-items {

    &::-webkit-scrollbar {
      width: .5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $orange1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $halforange;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background:  $mainorange;
    }

    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    @import "./journal-item";
  }
}
