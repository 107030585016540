.issue-related-issues {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    label {
      flex: 1;
      color: $gray8;
      font-size: .875rem;
    }

    &-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
    }
  }

  &-body {
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden;
    @import "./related-issue-item.scss";
  }

  &-empty {
    margin: 1rem;
    color: $gray8;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;
  }
}