.parameters-info{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  .items {
    flex: 1;
    padding: 0;
    line-height: 1.2rem;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .schema-parameter{
      &-object {
        font-size: .875rem;
        color: $dgray3;
        padding: 0 .2rem;
      }
      
      &-link{
        color: $dgrayblue1;
        font-size: 1.5rem;
      }

      &:after {
        content: "";
        border-right: .0625rem solid $dgray3;
        height: .875rem;
        margin-left: .5rem;
      }
      &:last-child:after{
        border-right:0;
      }
    }
  }
}