.tracer-table {
  min-width:100%;
  border-spacing: 0;
  overflow: auto;
  table-layout: fixed;

  border-collapse: collapse;
  thead {
    position: sticky;
    top: 0;
    z-index: 5;
    tr {
      background-color: $lightblue;
      color: $dblue3;
      th {
        border: 0.0625rem solid $gray4!important;
        padding:  .25rem .5rem;
        position: relative;
        background-clip: content-box;
        
        &.number {
          min-width: 2.5rem;
        }

        .link-direction {
          font-size: 2rem;
        }
      }
      &:first-child th {
        border-top-width: 0;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {background: $gray2}
      &:nth-child(odd) {background: $white}
      td{
        border: 0.0625rem solid $gray4;
        padding:  .25rem .5rem;
        position: relative;
        background-clip: content-box;

        &.gap, &.number {
          color: $dblue3;
        }
        
        .link-direction {
          font-size: 2rem;
          color: $dblue1;
        }

        .expected-direction{
          color: $mainred;
          font-size: 2rem;
          &.in{
            transform: rotate(-90deg);
          }
          &.out{
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}