@import "variables.scss";
.dropdown-button{
  &-content{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &.revert-button{
    .uif-components-button{
      flex-direction: row-reverse;
      padding: 0 0.25rem 0 0.5rem;
      svg{
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &-content{
    display: flex;
    flex-direction: row;  
    &.btn-group{
      .uif-components-button{
        margin: 0.125rem 0;
        &:first-child{
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 0 0 0 0.25rem;
        }

        &:last-child{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          width: auto;
          padding: 0 0.5rem 0 0.25rem;
          svg{
            width: 1.25rem;
            height: 1.25rem;
          } 
        }

        &:not(:first-child):not(:last-child){
          border-radius:0;
        }
      }
    }
  }
  
  position: relative;
  &-menu{
    display: none;
    right: 0;
    left: auto;
    list-style: none;
    margin: 0;
    flex-shrink: 0;
    position: absolute;
    padding: .3rem 0 .3rem .3rem;
    flex-wrap: wrap;
    z-index: 10;
    background: $dgray4;
    border-radius: 0 0 0.25rem 0.25rem;
    
    &-content{
      max-height: 10rem;
      overflow-y: auto;
      flex-direction: column;
    }
    
    &.show{
      display: block;
    }

    .menu-item{
      font-size: .85rem;
      min-height: 1.5rem;
      min-height: 1.25rem;
      padding: 0.25rem;
      color: white;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-radius: 0.25rem;
      transition: all .2s linear;
    
      &:hover {
        background-color: $dgray5;
        color: $mainblue;
        transition: all .2s linear;
      }
      &-title{
        text-align: left;
        margin: 0 .25rem;
        flex: 1;
      }
      .icon-svg{
        width: 1.25rem;
        height: 1.25rem;
      }
      .chevron-right{
        width: 1rem;
        height: 1rem;
        padding-left: .35rem;
      }
    }
  }
  &-submenu{
    position: absolute;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0.37rem;
    margin: 0;
    left: calc(100%);
    right: auto;
    top: 0;
  }

  
}