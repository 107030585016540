@import "colors.scss";
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  color: $dgrayblue1;
  font-size: .75rem;
  font-weight: 400;
  line-height: 2rem;
  padding: .5rem;
  background: $grayblue1;
  border: 0.0625rem solid $grayblue3;

  &.small {
    .page-title, .pages-title, .page-size-title {
      display: none;
    }
    .pagination-button {
      margin: 0 .25rem;
    }
  }
  &-controls, &-buttons-group, &-pages-info, &-page-size, .page-sizes{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .page-title, .pages-title, .page-size-title {
    white-space: nowrap;
  }

  &-pages-info{
    input, .pages-title {
      margin-left: .5rem;
    }

    input {
      width: 4rem;
      border: .0625rem solid $dgrayblue1;
      border-radius: .25rem;
      padding: .25rem;
    }
  }

  &-page-size {
    color: $dgrayblue1;
    .page-size-title{
      font-size: .75rem;
    }

    .page-size{
      font-size: .875rem;
      margin-left: .5rem;
      cursor: pointer;
      &.active {
        color: $dgrayblue3;
      }
    }
  }

  &-button {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5rem;
    color: $dgrayblue1;
    cursor: pointer;

    &.disabled{
      color: $gray4;
      cursor: default;
    }
  }
}
