.current-revision {
  .spacer {
    flex: 1;
  }
  .ais-button {
    height: auto !important;
    margin: 0.0625rem !important;
  }
  svg {
    margin: 0;
    padding: 0;
    margin-left: 0rem;
    font-size: 1rem;
  }
}