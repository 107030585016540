.markdown-view{
  flex: 1;
  padding: 0;
  margin: 0;
  background-color: transparent;
  & > * {
    margin: 0;
    color: $dgray5;
  }

  // line-height: 1.25rem;

  pre {
    background-color: $dgrayblue4;
    padding: .5rem;
    border-radius: 0.25rem;
  }

  .inline-block {
    background-color: $gray4;
    padding: .125rem 0.25rem;
  }

  blockquote {
    border-left: .25rem solid $halfgrayblue;
    background-color: $grayblue4;
    font-style: italic;
    padding: 0 1rem;
  }

  a {
    color: $mainblue;
  }

  ol, ul {
    // border: 1px solid #ccc;
  }
}
