.issues-kanban{
  flex: 1;
  display: flex;
  min-width: 1rem;
  &-wrapper {
    align-self:stretch;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: 0.5s ease-out;
    max-height: 100%;
    user-select: none;
  }

  .closed {
    max-height: 1.5rem;
  }

  &-content {
    flex: 1;
    margin: 0;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
