.issues-watchers{
  flex: 1;
  // flex-grow: 8;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  // overflow: hidden;
  padding: .5rem 0;

  &.label-on-top {
    flex-direction: column;
    .issues-watchers-label {
      padding: .2rem 0;
      // padding: 0 0 .5rem 0;
      // padding: .2rem 0;
      width: auto;
      // margin-top: -.3rem;
    }

    .issues-watchers-list, .issues-watchers-selectfield{
      // margin: .5rem 0;
    }
  }

  &-label {
    padding-top: .875rem;
    font-size: .875rem;
    color: $gray8;
    width: 6rem;
    min-width: 6rem;
    text-align: left;
  }

  &-list, &-selectfield{
    flex: 1;
    border-radius: .25rem;
    border: .0125rem solid $gray4;
  }
  &-selectfield {
    min-width: 1rem;
  }
  &-list {
    padding: 0.25rem 0.5rem;
    background-color: $gray3;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-selectfield {
    .select-field__multi-value {
      background-color: transparent;
      width: 100%;
    }
  }

  &-user {
    padding: .25rem 0;
  }
}
