.select-field {
  outline: none;
  &.read-only {
    .select-field__indicators{
      display: none;
    }
  }
  &__control{
    background: transparent!important;
    border:0!important;
    box-shadow: 0;
    font-size: .875rem;
    font-weight: 400;

  }

  .select-field__option{
    text-align: left;
  }

  &.invalid {
    border: .0625rem solid $mainred;
  }
}
