.schema-boundary-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border-bottom: 0.0625rem solid $gray6;
  &:last-child{
    border: 0;
  }

  &-title {
    font-size: .875rem;
    label{
      color: $dgray3;
      padding-right: .25rem;
      font-weight: 600;
    }
    padding-bottom: .5rem;
  }

  &-content{
    .object-class{
      flex: 1;
      padding: 0.25rem;
      border: 0.0625rem solid $gray6; 
      border-radius: 0.25rem;
      color: $gray8;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .object-wrapper{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .object-clear {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
      z-index: 5;
    }
    @import "./drop-place.scss";
  }
}