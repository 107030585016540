.issues-table{
  flex: 1;
  display: flex;
  min-width: 1rem;
  &-wrapper {
    align-self:stretch;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: 0.5s ease-out;
    max-height: 100%;
  }

  .rt-expander{
    &:after{
      border-top-color: $gray4!important;
      border-color: $gray4!important;
    }
  }

  .closed {
    max-height: 1.5rem;
  }

  &-columns-сhecks {
    display: flex;
    // padding: .5rem;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    // background-color: $grayblue3;
    // border-bottom: 0.0625rem solid $gray6;

    &-checkbox{
      padding: .5rem;
      color: $dgray1;
    }
  }

  &-content {
    flex: 1;
    margin: 0;
    // display: grid;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    overflow: auto;

    table {
      // flex: 1;
      width: auto;

      border-spacing: 0;
      border-collapse: separate;
      thead {
        position: sticky;
        top: 0;
        z-index: 5;
        background: $gray2;
        height: 2.5rem;
      }

      tr {
        &:nth-child(even) {background: $grayblue1;}

        // .select-item{
        //   color: $dblue1;
        //   font-size: 0.75rem;
        //   cursor: pointer;
        //   text-transform: none;
        //   padding-top: 0.25rem;
        //   white-space: wrap;  
        // }

        th {
          white-space: nowrap;
          text-align:center;
          font-size: .875rem;
          font-weight: 400;
          color: $dgray3;
          padding: 0 .5rem;
          border-top: 0.0625rem solid $gray6;
          border-bottom: 0.0625rem solid $gray6;
          border-right: 0.0625rem solid $gray6;
          &:first-child{
            border-left: 0.0625rem solid $gray6  
          } 

          &.selection-header{
            width: 2rem;
            max-width: 2rem;
            min-width: 2rem;
            svg{
              font-size: 1.5rem;
              cursor: pointer;
            }
          }

          &.tracker-header{
            width: 2rem;
            max-width: 2rem;
            min-width: 2rem;
          }
          &.id-header{
            width: 4.5rem;
            min-width: 4.5rem;
            max-width: 8rem;
          }
          &.subject-header{
            width: 16rem;
            min-width: 16rem;
          }

          &.statusId-header, &.priorityId-header {
            width: 5rem;
            min-width: 5rem;
            max-width: 5rem;
          }
          &.doneRatio-header{
            width: 6rem;
            min-width: 6rem;
            max-width: 6rem;
          }

          &.startDate-header, &.dueDate-header{
            width: 6rem;
            min-width: 6rem;
            max-width: 6rem;
          }

          &.createdOn-header, &.updatedOn-header{
            width: 8rem;
            min-width: 8rem;
            max-width: 8rem;
          }

          &.wathers-header{
            width: 13rem;
            min-width: 10rem;
          }
          
          &.author-header, &.assignedTo-header{
            width: 10rem;
            min-width: 8rem;
          }

          &.project-header{
            width: 10rem;
            min-width: 10rem;
          }
        }

        td {
          align-items: flex-start;
          justify-content: flex-start;
          padding:0;
          margin: 0;
          overflow: hidden;
          text-align: left;
          vertical-align: top;

          border-bottom: 0.0625rem solid $gray6;
          border-right: 0.0625rem solid $gray6;
          &:first-child{
            border-left: 0.0625rem solid $gray6  
          }
        }

        &.expired {
          td{
            border-bottom: 0.0625rem solid $red3;
          }
          background-color: $lightred;
          &:first-child{
            td {
              border-top-width: 0;  
            }
          }
        }
        &.next-expired{
          td{
            border-bottom: 0.0625rem solid $red3;
          }          
        }
      }
    }
  }

  &-cell {
    // height: 100%;
    // width: 100%;
    
    padding: .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    
    font-size: .875rem;
    font-weight: 100;

    overflow: hidden;

    // justify-content: center;
    // align-items: flex-start;

    &.selection-cell{
      align-items: center;
      justify-content: center;
      svg{
        font-size: 1.5rem;
        cursor: pointer;
      }
    }

    &-text{
      white-space: pre-wrap;
      text-align: left;
    }
    &-title {
      cursor: pointer;
    }

    &-preloader{
      position: absolute;
      margin: auto;
      display: block;
      width: 2rem;
      height: 2rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      svg {
        margin: 0.25rem;
        margin-left: .125rem;
      }
    }

    
    &.id-cell {
      width: 100%;
      position: relative;
    }

    &.subject-cell{
      flex: 1;
    }

    &.tracker-cell{
      align-items: center;
      justify-content: center;
      margin: auto;
      padding-top: 0.25rem;
      svg{
        color: $dgrayblue1;
      }
    }


    &.dueDate-cell{
      justify-content: space-between;
    }

    .overdue {
      color: $mainred;
      justify-content: space-between;
      display: flex;
      flex: 1;
      &:after{
        content: " !";
        text-align: right;
        font-weight: bold;
      }
    }

    &-id{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      color: $dgrayblue1;
    }
    &-tag {
      margin-top: -.125rem;
      border-radius: .25rem;
      padding: 0.125rem .5rem;
      border-width: .0625rem;
      border-style: solid;
    }
  }
}
