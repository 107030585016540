.attr-field-condition {
  color: $dgray3;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  text-align: center;
  position: relative;
  border: 0.0625rem solid $gray7;
  background-color: $gray4;
  border-radius: 0.25rem;
  margin: 0 .25rem 0 0;
  flex-shrink: 0;
  label {
    user-select: none;
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

}
@import "./picker.scss";