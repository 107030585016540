.new-item {
  border-bottom: 0.0625rem dotted $dviolet1;
  
  .add-kind-form {
    // padding: 0 0.5rem 0.5rem 0.5rem;
    padding: 0 .75rem 0.5rem .75rem;
    text-align: left;
  }

  &.form-expanded {
    background-color: $violet3;

    .uif-components-button {
      &:not(.disabled):not(.outline):hover {
        box-shadow: none;
        color: white !important;
        background-color: $dviolet3 !important;
      }
    }
  }

  .form-holder {
    .relation-type-holder {
      margin: 0.5rem;

      .uif-components-button {
        background-color: $dviolet2 !important;
        color: white !important;
        justify-content: flex-start;
        height: 1rem;
        font-size: 0.875rem;

        &:before {
          content: none;
        }

        &:not(.disabled):not(.outline):hover {
          box-shadow: none;
          background-color: $dviolet3 !important;
        }
      }

      .selected-item {
        border: none;
      }
    }
  }
}