@import "variables";

.workflow-content {
  .form-holder {
    min-height: 8rem;
    background-color: $gray4;
    padding: 0 0 0.5rem;
    display: flex;
    flex-direction: column;
    .machines-list {
      background-color: $gray4;
      display: flex;
      flex-direction: column;
      text-align: start;
      padding: 0.5rem;
      margin: 0;
      .machines-list__btn {
        padding-top: 1.75rem;
        margin-left: auto;
      }
    }
    .machines-empty {
      display: inline-block;
      color: #999;
      font-size: 1.1rem;
      font-weight: 300;
      margin-top: 2rem;
      align-self: center;
    }
  }
  .machine-toggle-btn {
    display: flex;
    justify-content: end;
    margin-right: .5rem;
  }
  .new-item {
    &.form-expanded {
      background-color: $gray4;
    }
  }
}