table {
  flex: 1;
  align-self: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  border: none;

  table-layout: fixed;

  border-collapse: collapse;
  thead {
    position: sticky;
    top: 0;
    z-index: 5;
    tr {
      th {
        div {
          padding: 0.25rem;
        }
        border: 0.0625rem solid #d9d9d9;
        border-top-width: 0.0625rem;
        padding: 0;
        position: relative;
        background-clip: content-box;
        background-color: $lightblue;
      }
      &:first-child th {
        border-top-width: 0;
      }
    }
  }
  tbody {
    td {
      padding: 0.25rem;
      border: 0.0625rem solid $gray5;

      .ais-object {
        padding: 0.25rem;
      }

      .ais-field-container.no-label {
        margin: 0rem 0.25rem;
      }
    }
  }
  .icon.negative {
    color: red;
  }
  .frs-num {
    width: 4rem;
  }
  .user-value {
    max-width: 6rem;
    min-width: 6rem;
    overflow: hidden;
    position: relative;

    &.text{
      min-width: 25rem;
      max-width: 25rem;
    }
    // &.yes:not(div.editing) {
    //   background-color: $halfgreen;
    //   color: $dgreen1;
    // }
    // &.no {
    //   background-color: $halfred;
    //   color: $dred1;
    // }
  }

  .editing {
    width: 100%;
    overflow: hidden;
    display: flex;
    .ais-input-holder,
    .ais-input-row,
    .ais-input-div {
      flex: 1;
      overflow: hidden;
      margin: 0 0.0625rem;
    }
  }

  .boolean-value,
  .selectable-value {
    display: flex;
    align-items: center;
    justify-content: center;
    &.yes,
    &.no {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &.yes:not(.editing) {
      background-color: $halfgreen;
      color: $dgreen1;
    }
    &.no:not(.editing) {
      background-color: $halfred;
      color: $dred1;
    }
  }
}
