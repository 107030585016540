.coord-letter-step{
  min-width: 30rem;
  // max-height: 30rem;

  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-header{
    h2 {
      margin: .75rem;
      font-size: 1rem;
      font-weight: 400;
    }
    border-bottom: 0.0625rem solid $dgray2;
  }
  &-body{
    flex: 1;
    display: flex;
    flex-direction: column;
    // min-height: 15rem;
    margin: .5rem;
    overflow: hidden;
    // font-size: .85rem;
    font-size: 1rem;

    .data-content{
      flex: 1;
      display: block;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      margin: .5rem;
      overflow: auto;
    }
    .table-content{
      flex: 1;
      background: $dgray5;
      padding: 0;
      margin: .5rem;
      border-radius: 0.25rem;
      overflow: auto;

      color: $gray4;
    }

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;
      thead {
        position: sticky;
        top: 0;
        z-index: 5;
        background: $dgray5;
        // background: $gray7;
        // height: 1.5rem;
      }
      
      tr {
        &:nth-child(even) {background: $dgray4;}
        // &:nth-child(odd) {background: $dgray3;}
        th {
          white-space: wrap;
          text-align:center;
          // font-size: .875rem;
          font-weight: 400;
          padding: 0.5rem .5rem;
          border-top: 0.0625rem solid $dgray4;
          border-bottom: 0.0625rem solid $dgray4;
          border-right: 0.0625rem solid $dgray4;
          &:first-child{
            border-left: 0.0625rem solid $dgray4;  
          } 
          &.check-box{
            width: 5rem;
            max-width: 5rem;
            min-width: 5rem;

            .select-item {
              color: $dblue1;
              font-size: 0.75rem;
              cursor: pointer;
              text-transform: none;
              padding-top: 0.25rem;
              white-space: wrap;  
            }
          }
        }

        td {
          padding:0;
          margin: 0;
          overflow: hidden;
          text-align: left;
          border-bottom: 0.0625rem solid $dgray4;
          border-right: 0.0625rem solid $dgray4;
          &:first-child{
            border-left: 0.0625rem solid $dgray4  
          }

          .title{
            padding: .5rem;
          }
          .check-box{
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
              font-size: 1.5rem;
              cursor: pointer;
            }
          }          
        }
      }
    }
  }
}