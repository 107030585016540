.issue-journal-add-notes{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: .25rem .25rem .25rem .75rem;
  .add-notes {
    &-add-button {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: .875rem;
      text-align: left;
      cursor: pointer;
      color: $dorange2;
      &.disbled {
        color: $orange3;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      &-label {
        flex: 1;
      }
    }
    &-content {
      margin: .5rem .5rem;
      overflow-x: hidden;
      &-buttons {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-right: -.5rem;
        .ais-button{
          border-radius: .25rem;
          &:not(.outline){
            color: $white!important;
            background-color: $mainorange!important;
          }
          &.disabled {
            color: $white!important;
            background-color: $orange1!important;
          }
        }

      }
      &-text {
        .journal-item-notes {
          min-height: 6rem;
        }
        .text-area-body{
          flex: 1;
          display: flex;
          align-items: stretch;
          margin-top: .5rem;
          textarea {
            width: 100%;
            font-size: 1rem;
            padding: .5rem;
            overflow: auto;
            border: .0625rem solid $dorange1;
            border-radius: .25rem;
            background-color: $white;
            color: $dorange3;
          }
        }
      }
    }
  }
}
