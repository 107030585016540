@import "variables.scss";
.preloader-holder {
  flex: 1;
  height: 5rem;
  min-width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-content {
  padding-top: 0.5rem;

  .service {
    background: $dgray5;
    margin: 0.0625rem 0;
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-radius: 0rem;

    &:first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }

    div {
      cursor: pointer;
      margin: 0 0.5rem;
      border-right: 0.0625rem solid $dgray4;

      &.name {
        flex: 1;
        width: 12rem;
        padding-right: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.version {
        width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        &.noCompatible {
          // color: #d9534f;
          background: $mainred;
        }
        &.compatible {
          width: 7rem;
        }
      }
      &.build {
        width: 4rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.commit {
        flex: 1;
        width: 8rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.status {
        border: none;
        padding: 0;
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        // background: #ffaa44;
        background: $mainorange;
        overflow: hidden;
        text-overflow: ellipsis;

        &.dead {
          // background: #d9534f;
          background: $mainred;
        }
        &.alive {
          // background: #5cb85c;
          background: $maingreen;
        }
        &.header {
          background: transparent;
        }
      }
    }
  }
}
