.check-list-content-data {
  counter-reset: urs;
  padding-bottom: 50%;

  .urs-num-content {
    counter-increment: urs;
    color: $mainblue;
    cursor: pointer;
    // &:after {
    // content: "#" counter(urs);
    // }
  }

  .selectable-value {
    min-width: 100%;
    cursor: pointer;
  }
}

.frs-target {
  flex: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .dnd-target {
    flex: 1;
    display: flex;
    align-items: stretch;

    .empty {
      flex: 1;
      border: 0.15rem dashed $gray6;
      padding: 0.25rem;
      color: $gray8;
    }
  }
}
