.new-item {
  .button-holder {
    display: flex;
    padding: 0.25rem 0.5rem;
    align-items: center;
    justify-content: flex-end;

    > .uif-components-button {
      // background: transparent !important;
      // color: $fontcolor !important;
      background: $dcyan2 !important;
      color: $white !important;
      height: 2rem;
      font-size: 0.875rem;

      &:before {
        content: none;
      }

      &:not(.disabled):not(.outline):hover {
        box-shadow: none;
        color: white !important;
        background-color: $dcyan3 !important;
      }
    }
  }

  &.form-expanded {
    background-color: $cursorbackground;
    border-bottom: 0.0625rem dotted $dcyan1;
    // background-color: $cyan3;
  }

  .form-holder {
    margin: -1rem 0.75rem 0 0.75rem;
    .relation-type-holder {
      text-align: left;

      .selected-item {
        border: none;
      }

      .theme-relation{
        .ais-field-label{
          color: $fontcolor;
        }
      }
    }
  }
}