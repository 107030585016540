.kind-group {
  position: relative;
  .item-header {
    display: flex;
    flex-direction: row;
    height: 2rem;
    align-items: center;
    padding: 0 0.25rem;
    fill: $dviolet2;
    color: $dviolet2;

    .item-name {
      margin-left: 0.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: 600;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .spacer {
      flex: 1;
      height: 0.0625rem;
      background: $violet3;
    }

    .delete {
      width: 1.5rem;
      height: 1.5rem;
      flex-shrink: 0;

      &:hover {
        color: $mainred;
      }
    }

    .save {
      width: 1.5rem;
      height: 1.5rem;
      flex-shrink: 0;

      &:hover {
        color: $maingreen;
      }
    }

    .revert {
      width: 1.5rem;
      height: 1.5rem;
      flex-shrink: 0;

      &:hover {
        color: $mainblue;
      }
    }

    svg {
      cursor: pointer;
      flex-shrink: 0;
      margin: 0.25rem;

      &.lock {
        flex-shrink: initial;
      }
    }
  }

  .related-list {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
    color: $dviolet1;
    text-align: left;
  }
}
